<template>
  <div class="location-intro">
    <h1>PARK HILL</h1>
    <p>{{ this.$t("location.addresstop", this.$store.state.locale) }}</p>
    <p>{{ this.$t("location.addressbottom", this.$store.state.locale) }}</p>
    <div class="location-images">
      <div>
        <img ref="img1" class="img1" src="../assets/parkhill/parkhill2.png">
        <img ref="img2" class="img2" src="../assets/parkhill/parkhill3.png">
        <img ref="img3" class="img3" src="../assets/parkhill/parkhill1.png">
      </div>
    </div>
  </div>
  <div class="location-separator">
    <p>{{ this.$t("location.intro", this.$store.state.locale) }}</p>
  </div>
  <div class="location-how-to">
    <div class="location-how-to-container">
      <div class="location-how-to-title">
        <h1>{{ this.$t("location.howto", this.$store.state.locale) }}</h1>
      </div>
      <div class="location-map">
        <img src="../assets/location_map.jpg"/>
      </div>
      <div class="location-hints">
        <div>
          <h3>{{ this.$t("location.cartitle", this.$store.state.locale) }}</h3>
          <p>{{ this.$t("location.car", this.$store.state.locale) }}</p>
          <div class="location-method-search" >
            <input type="text" id="location-dest" name="location-dest" v-model="startPoint">
            <button v-on:click="goToGmaps()">{{ this.$t("location.findpath", this.$store.state.locale) }}</button>
          </div>
        </div>
        <div>
          <h3>{{ this.$t("location.taxititle", this.$store.state.locale) }}</h3>
          <p>{{ this.$t("location.taxi", this.$store.state.locale) }}</p>
        </div>
        <div>
          <h3>{{ this.$t("location.bustitle", this.$store.state.locale) }}</h3>
          <p>{{ this.$t("location.bus", this.$store.state.locale) }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Location',
  mounted() {
    this.scrollToTop()
    window.addEventListener('resize', () => {
      if(window.innerWidth > 800) {
        this.showAll()
      }
    });
    setInterval(() => {
      if(window.innerWidth < 800) {
        this.translate()
      }
    }, 4000)
  },
  data() {
    return {
      startPoint: "",
      data: 0
    }
  },
  methods: {
    goToGmaps: function () {
      let link = `https://maps.google.com?saddr=${this.startPoint.replace(" ", "+")}&daddr=Park+Hill+rendezv%C3%A9nyhelysz%C3%ADn`
      Object.assign(document.createElement('a'), {
        target: '_blank',
        href: link,
      }).click();
    },
    showAll() {
      this.data = 0
        this.$refs.img1.style.visibility = `visible`
        this.$refs.img1.style.opacity = 1
        this.$refs.img2.style.visibility = `visible`
        this.$refs.img2.style.opacity = 1
        this.$refs.img3.style.visibility = `visible`
        this.$refs.img3.style.opacity = 1
    },
    translate() {
      this.data++
      //let width = window.innerWidth + 50
      if(this.data === 1){
        this.$refs.img3.style.visibility = `hidden`
        this.$refs.img3.style.opacity = 0
        //this.$refs.img1.style.display = "block"
        this.$refs.img1.style.visibility = `visible`
        this.$refs.img1.style.opacity = 1
      }
      if(this.data === 2){
        //this.$refs.img1.style.display = "none"
        this.$refs.img1.style.visibility = `hidden`
        this.$refs.img1.style.opacity = 0
        //this.$refs.img2.style.display = "block"
        this.$refs.img2.style.visibility = `visible`
        this.$refs.img2.style.opacity = 1
      }
      if(this.data === 3){
        //this.$refs.img2.style.display = "none"
        this.$refs.img2.style.visibility = `hidden`
        this.$refs.img2.style.opacity = 0
        this.data = 0
        this.$refs.img3.style.visibility = `visible`
        this.$refs.img3.style.opacity = 1
      }
    }
  }
}
</script>

<style lang="sass">
@use "../assets/scss/_variables.sass"
.location-intro
  display: flex
  flex-direction: column
  align-items: center
  h1
    margin: 5% 0 0 0 !important
  p
    margin: 0 !important
  .location-images
    display: flex
    justify-content: center
    width: 48%
    margin-top: 3%
    div
      margin-bottom: -6%
      display: flex
      justify-content: space-between
      width: 100%
      img
        border: 10px solid white
        height: 150px
        width: auto

.location-separator
  background-color: variables.$primary-light-orange
  display: flex
  justify-content: center
  p
    padding: 5% 0 3% 0
    width: 60%

.location-how-to
  margin: 2% 0 0 0
  background-image: url("../assets/sodo_footer_flowers.jpg")
  background-size: auto 260px
  background-repeat: no-repeat
  background-position-y: bottom
  background-position-x: center
  display: flex
  justify-content: center
  .location-how-to-container
    width: 80%
    display: flex
    flex-direction: column
    align-items: center
    .location-how-to-title
      display: flex
      justify-content: flex-start
      width: 100%
    .location-map
      width: 600px
      img
        width: 100%
        height: auto
    .location-hints
      display: flex
      justify-content: space-between
      width: 90%
      margin: 6% 0 8% 0
      .location-method-search
        width: 100%
        display: flex
        flex-direction: column
        align-items: center
        input[type="text"]
          height: 25px
          border: 1px solid variables.$primary-orange
          background-color: variables.$secondary-font-color
          width: 80%
          margin-bottom: 2%
        button
          background-color: variables.$primary-orange
          border: none
          color: variables.$secondary-font-color
          text-align: center
          text-decoration: none
          padding: 1% 2% 1% 2%
      div
        width: 30%
        h3
          font-family: variables.$opensans-italic

@media screen and (max-width: 1650px)
  .location-intro
    .location-images
      width: 55%

@media screen and (max-width: 1500px)
  .location-intro
    .location-images
      width: 65%

@media screen and (max-width: 1200px)
  .location-intro
    .location-images
      width: 75%

@media screen and (max-width: 1050px)
  .location-intro
    .location-images
      width: 85%

@media screen and (max-width: 1000px)
  .location-how-to
    background-size: auto 200px

@media screen and (max-width: 900px)
  .location-intro
    .location-images
      width: 95%

  .location-how-to
    .location-how-to-container
      .location-hints
        display: flex
        flex-direction: column
        align-items: center
        div
          width: 50%
          h3
            margin-bottom: 1%

@media screen and (max-width: 800px)
  .location-intro
    .location-images
      div
        position: relative
        width: 245px
        height: 170px
        img
          transition: visibility 0.8s ease-out
          transition: opacity 0.8s ease-out
          position: absolute
          top: 0
          left: 0

@media screen and (max-width: 700px)
  .location-how-to
    .location-how-to-container
      .location-map
        width: 500px

@media screen and (max-width: 600px)
  .location-how-to
    background-size: auto 160px
    .location-how-to-container
      .location-map
        width: 450px
      .location-hints
        div
          width: 70%

@media screen and (max-width: 500px)
  .location-how-to
    .location-how-to-container
      .location-map
        width: 100%
      .location-hints
        div
          width: 85%
</style>