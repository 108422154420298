<template>
  <div class="menu-grid-container">
    <div class="menu">
      <!--<router-link :to="{path: `/${this.lang}/take-part`}" v-slot="{isActive}">
        <span :class="{active: isActive}">{{ this.$t("menu.participation", this.$store.state.locale) }}</span>
      </router-link>-->
      <router-link :to="{path: `/${this.lang}/gallery`}" v-slot="{isActive}">
        <span :class="{active: isActive}">{{ this.$t("menu.gallery", this.$store.state.locale) }}</span>
      </router-link>
      <router-link :to="{path: `/${this.lang}/about-us`}" v-slot="{isActive}">
        <span :class="{active: isActive}">{{ this.$t("menu.us", this.$store.state.locale) }}</span>
      </router-link>
      <router-link to="/">
        <span class="logo-item"><img class="logo-img" src="~@/assets/sodo_logo.svg" alt="sodo-logo"/></span>
      </router-link>
      <router-link :to="{path: `/${this.lang}/location`}" v-slot="{isActive}">
        <span :class="{active: isActive}">{{ this.$t("menu.location", this.$store.state.locale) }}</span>
      </router-link>
      <router-link :to="{path: `/hu/info`}" v-if="this.lang === 'hu'"  v-slot="{isActive}">
        <span :class="{active: isActive}">{{ this.$t("menu.info", this.$store.state.locale) }}</span>
      </router-link>
      <router-link :to="{path: `/it/info`}" v-else-if="this.lang === 'it'" v-slot="{isActive}">
        <span :class="{active: isActive || this.$route.name === 'Accommodation' || this.$route.name === 'Travel' || this.$route.name === 'Vacation'}">{{ this.$t("menu.info", this.$store.state.locale) }}</span>
      </router-link>
    </div>
    <div class="lang">
      <button v-on:click="setLocale('it')"><span :class="localeIt">IT</span></button><span class="separator">|</span><button v-on:click="setLocale('hu')"><span :class="localeHu">HU</span></button>
    </div>
  </div>

  <div class="mobile-menu-top">
    <a href="javascript:void(0);" class="icon" v-on:click="show">
      <img class="icon-menu" ref="iconopen" src="../assets/menu_open.svg" style="display: none">
      <img class="icon-menu" ref="iconclose" src="../assets/menu_close.svg">
    </a>
    <router-link to="/">
      <img class="logo-img" src="~@/assets/sodo_logo.svg" alt="sodo-logo"/>
    </router-link>
    <div class="lang">
      <button v-on:click="setLocale('it')"><span :class="localeIt">IT</span></button><span class="separator">|</span><button v-on:click="setLocale('hu')"><span :class="localeHu">HU</span></button>
    </div>
  </div>
  <div ref="drop" class="mobile-menu-dropdown">
    <div class="mobile-menu">
      <ul class="mobile-menu-list">
        <!--<router-link :to="{path: `/${this.lang}/take-part`}" v-on:click="unShow" v-slot="{isActive}">
          <li :class="{active: isActive}">{{ this.$t("menu.participation", this.$store.state.locale) }}</li>
        </router-link>-->
        <router-link :to="{path: `/${this.lang}/gallery`}" v-on:click="unShow" v-slot="{isActive}">
          <li :class="{active: isActive}">{{ this.$t("menu.gallery", this.$store.state.locale) }}</li>
        </router-link>
        <router-link :to="{path: `/${this.lang}/about-us`}" v-on:click="unShow" v-slot="{isActive}">
          <li :class="{active: isActive}">{{ this.$t("menu.us", this.$store.state.locale) }}</li>
        </router-link>
        <router-link :to="{path: `/${this.lang}/location`}" v-on:click="unShow" v-slot="{isActive}">
          <li :class="{active: isActive}">{{ this.$t("menu.location", this.$store.state.locale) }}</li>
        </router-link>
        <router-link :to="{path: `/hu/info`}" v-if="this.lang === 'hu'" v-on:click="unShow" v-slot="{isActive}">
          <li :class="{active: isActive}">{{ this.$t("menu.info", this.$store.state.locale) }}</li>
        </router-link>
        <router-link :to="{path: `/it/info`}" v-else-if="this.lang === 'it'" v-on:click="unShow" v-slot="{isActive}">
          <li :class="{active: isActive || this.$route.name === 'Accommodation' || this.$route.name === 'Travel'}">{{ this.$t("menu.info", this.$store.state.locale) }}</li>
        </router-link>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Menu',
  created () {
    window.addEventListener('scroll', this.unShow);
  },
  mounted() {
    this.show()
  },
  methods: {
    setLocale(locale) {
      this.lang = locale
      window.location.reload()
    },
    unShow() {
      this.$refs.drop.style.display = "none"
      this.$refs.iconclose.style.display = "none"
      this.$refs.iconopen.style.display = "block"
    },
    show() {
      if(this.$refs.drop.style.display === "none"){
        this.$refs.drop.style.display = "block"
      } else {
        this.$refs.drop.style.display = "none"
      }

      if(this.$refs.iconopen.style.display === "none"){
        this.$refs.iconclose.style.display = "none"
        this.$refs.iconopen.style.display = "block"
      } else {
        this.$refs.iconclose.style.display = "block"
        this.$refs.iconopen.style.display = "none"
      }

    }
  },
  computed: {
    lang: {
      get: function() {
        return this.$store.state.locale
      },
      set: function(newVal) {
        this.$store.dispatch('changeLocale', newVal)
      }
    },
    localeIt: function () {
      return {
        active: this.$store.state.locale === 'it'
      }
    },
    localeHu: function () {
      return {
        active: this.$store.state.locale === 'hu'
      }
    }
  }
}
</script>

<style lang="sass">
@use "../assets/scss/_variables.sass"
.mobile-menu-top
  display: none
  .lang
    display: flex
    button
      display: flex
      justify-content: center
      align-items: center
      text-decoration: none
      width: 30px
      height: 30px
      .active
        border-bottom: variables.$secondary-font-color solid 1px
    .separator
      color: variables.$secondary-font-color

.menu-grid-container
  display: grid
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr
  grid-template-rows: 1fr
  gap: 0px 0px
  grid-template-areas: ". menu menu menu menu menu . lang"
  background-color: variables.$primary-orange
  height: 90px
  margin: 0
  padding: 0
  .menu
    grid-area: menu
    display: flex
    justify-content: center
    align-items: center
    a
      margin: 0 1.5% 0 1.5%
      text-decoration: none
      color: variables.$secondary-font-color
      font-size: 14px
      .active
        border-bottom: variables.$secondary-font-color solid 1px
      .logo-item
        display: flex
        justify-content: center
        transform: scale(1.5)
        img
          position: relative
          width: 62px
    a:nth-child(1)
      margin-right: 5%
    a:nth-child(2)
      margin-right: 5%
    a:nth-child(4)
      margin-left: 5%
    a:nth-child(5)
      margin-left: 5%
  .lang
    grid-area: lang
    display: flex
    justify-content: center
    align-items: center
    color: variables.$secondary-font-color
    button
      background: none
      color: inherit
      border: none
      padding: 0
      font: inherit
      cursor: pointer
      outline: inherit
      width: 30px
      height: 30px
    .active
      border-bottom: variables.$secondary-font-color solid 1px
    button:last-child
      text-align: end

@media screen and (max-width: 900px)
  .menu-grid-container
    display: none

  .mobile-menu-top
    background-color: variables.$primary-orange
    display: flex
    justify-content: space-between
    align-items: center
    height: 70px
    list-style-type: none
    margin: 0
    padding: 0
    a
      margin: 0
      text-decoration: none
      color: variables.$secondary-font-color
      width: 150px
      height: 70px
      img
        position: relative
        width: 70px
        height: 70px
    .icon
      display: flex
      justify-content: flex-start
      .icon-menu
        margin-left: 10%
        width: 45px
        height: auto
    .lang
      display: flex
      justify-content: center
      align-items: center
      button
        color: variables.$secondary-font-color
        background: none
        border: none
        padding: 0
        font: inherit
        cursor: pointer
        outline: inherit
    div
      width: 150px
      height: 70px

  .mobile-menu-dropdown
    .mobile-menu
      z-index: 2
      height: calc(100vh - 70px)
      width: 200px
      position: absolute
      top: 70px
      margin: 0
      padding: 2% 0 0 0
      background-color: variables.$primary-orange
      background-image: url("../assets/menu_flowers.svg")
      background-size: auto
      background-repeat: no-repeat
      background-position-y: bottom
      background-position-x: center
      display: flex
      justify-content: center
      .mobile-menu-list
        list-style-type: none
        text-align: start
        width: 80%
        padding: 0
        a
          text-decoration: none
          color: variables.$secondary-font-color
          li
            margin: 12% 0 12% 0



</style>
