<template>
  <h1>{{ this.$t("us.title", this.$store.state.locale) }}</h1>
  <div class="about-us-intro">
    <p class="about-us-intro-paragraph">{{ this.$t("us.intro", this.$store.state.locale) }}</p>
    <p class="about-us-intro-login" v-if="this.$store.state.token === ''">{{ this.$t("us.race", this.$store.state.locale) }} <a href="#" v-on:click="toggleRegister">{{ this.$t("us.register", this.$store.state.locale) }}</a> {{ this.$t("us.connector", this.$store.state.locale) }} <a href="#" v-on:click="toggleLogIn">{{ this.$t("us.access", this.$store.state.locale) }}</a>.</p>
    <div class="login-game-container" v-else>
      <p>{{ this.$t("us.welcome", this.$store.state.locale) }} <span>{{this.$store.state.sub}}</span>, {{ this.$t("us.points", this.$store.state.locale) }} <span>{{this.$store.state.points}}</span></p>
      <p><a href="#" v-on:click="logout">{{ this.$t("us.logout", this.$store.state.locale) }}</a></p>
    </div>
    <button v-on:click="toggleLeaderboard">{{ this.$t("us.leaderboard", this.$store.state.locale) }}</button>
  </div>
  <div class="game">
    <Game :points="this.$store.state.points"/>
  </div>
  <div class="about-us-container">
    <div class="about-us-info-item">
      <div class="fact-container">
        <div class="position">
          <p v-if="isLittle">1.&#xa0;&#x2015;&#x2015;&#x2015;&#x2015;&#x2015;</p>
          <p v-else>1.&#xa0;&#x2015;&#x2015;&#x2015;&#x2015;&#x2015;&#x2015;&#x2015;&#x2015;</p>
        </div>
        <p v-bind:class="{ fact: factCount < 1, 'fact-active': factCount >= 1 }">{{factCount >= 1 ? facts[0] : fakeFacts[0]}}</p>
      </div>
      <div class="fact-container">
        <p v-bind:class="{ fact: factCount < 2, 'fact-active': factCount >= 2 }">{{factCount >= 2 ? facts[1] : fakeFacts[1]}}</p>
        <div class="position">
          <p v-if="isLittle">&#x2015;&#x2015;&#x2015;&#x2015;&#x2015;&#xa0;2.</p>
          <p v-else>&#x2015;&#x2015;&#x2015;&#x2015;&#x2015;&#x2015;&#x2015;&#x2015;&#xa0;2.</p>
        </div>
      </div>
      <div class="fact-container">
        <div class="position">
          <p v-if="isLittle">3.&#xa0;&#x2015;&#x2015;&#x2015;&#x2015;&#x2015;</p>
          <p v-else>3.&#xa0;&#x2015;&#x2015;&#x2015;&#x2015;&#x2015;&#x2015;&#x2015;&#x2015;</p>
        </div>
        <p v-bind:class="{ fact: factCount < 3, 'fact-active': factCount >= 3 }">{{factCount >= 3 ? facts[2] : fakeFacts[2]}}</p>
      </div>
      <div class="fact-container">
        <p v-bind:class="{ fact: factCount < 4, 'fact-active': factCount >= 4 }">{{factCount >= 4 ? facts[3] : fakeFacts[3]}}</p>
        <div class="position">
          <p v-if="isLittle">&#x2015;&#x2015;&#x2015;&#x2015;&#x2015;&#xa0;4.</p>
          <p v-else>&#x2015;&#x2015;&#x2015;&#x2015;&#x2015;&#x2015;&#x2015;&#x2015;&#xa0;4.</p>
        </div>
      </div>
      <div class="fact-container">
        <div class="position">
          <p v-if="isLittle">5.&#xa0;&#x2015;&#x2015;&#x2015;&#x2015;&#x2015;</p>
          <p v-else>5.&#xa0;&#x2015;&#x2015;&#x2015;&#x2015;&#x2015;&#x2015;&#x2015;&#x2015;</p>
        </div>
        <p v-bind:class="{ fact: factCount < 5, 'fact-active': factCount >= 5 }">{{factCount >= 5 ? facts[4] : fakeFacts[4]}}</p>
      </div>
      <div class="fact-container">
        <p v-bind:class="{ fact: factCount < 6, 'fact-active': factCount >= 6 }">{{factCount >= 6 ? facts[5] : fakeFacts[5]}}</p>
        <div class="position">
          <p v-if="isLittle">&#x2015;&#x2015;&#x2015;&#x2015;&#x2015;&#xa0;6.</p>
          <p v-else>&#x2015;&#x2015;&#x2015;&#x2015;&#x2015;&#x2015;&#x2015;&#x2015;&#xa0;6.</p>
        </div>
      </div>
      <div class="fact-container">
        <div class="position">
          <p v-if="isLittle">7.&#xa0;&#x2015;&#x2015;&#x2015;&#x2015;&#x2015;</p>
          <p v-else>7.&#xa0;&#x2015;&#x2015;&#x2015;&#x2015;&#x2015;&#x2015;&#x2015;&#x2015;</p>
        </div>
        <p v-bind:class="{ fact: factCount < 7, 'fact-active': factCount >= 7 }">{{factCount >= 7 ? facts[6] : fakeFacts[6]}}</p>
      </div>
      <div class="fact-container">
        <p v-bind:class="{ fact: factCount < 8, 'fact-active': factCount >= 8 }">{{factCount >= 8 ? facts[7] : fakeFacts[7]}}<a href="https://youtu.be/U5QwYj3OWUg" target="_blank">IKEA 2016</a></p>
        <div class="position">
          <p v-if="isLittle">&#x2015;&#x2015;&#x2015;&#x2015;&#x2015;&#xa0;8.</p>
          <p v-else>&#x2015;&#x2015;&#x2015;&#x2015;&#x2015;&#x2015;&#x2015;&#x2015;&#xa0;8.</p>
        </div>
      </div>
    </div>
  </div>
  <div class="about-us-image">
    <div class="image-container">
      <img ref="img1" src="../assets/1.jpg"/>
      <img ref="img2" src="../assets/2.jpg"/>
      <img ref="img3" src="../assets/3.jpg"/>
    </div>
  </div>
  <div class="about-us-base"></div>
  <!-- Leaderboard modal -->
  <div ref="leaderboard" id="leaderboard" class="modal leaderboard" style="display: none">
    <div class="modal-content leaderboard-content">
      <Leaderboard ref="leaderboardComp" v-on:closeLeaderboard="toggleLeaderboard"/>
    </div>
  </div>
  <!-- LogIn modal -->
  <div ref="login" id="login" class="modal login" style="display: none">
    <div class="modal-content login-content">
      <LogIn v-on:closeLogin="toggleLogIn" v-on:restoreFact="restoreFact" :psw-only=false />
    </div>
  </div>
  <!-- LogIn modal -->
  <div ref="register" id="register" class="modal register" style="display: none">
    <div class="modal-content register-content">
      <Register v-on:closeRegister="toggleRegister"/>
    </div>
  </div>

</template>

<script>
import Game from "@/components/Game";
import Leaderboard from "@/components/Leaderboard";
import LogIn from "@/components/LogIn";
import Register from "@/components/Register";

export default {
  name: 'Us',
  components: {Register, LogIn, Leaderboard, Game},
  created() {
    let self = this;
    document.addEventListener( 'points', function(e) {
      self.addFact(e.detail.points)
    })
    document.addEventListener( 'gameover', function(e) {
      self.checkValueAndSend(e.detail.points)
    })
    if(this.$store.state.token !== null && this.$store.state.token.length > 0){
      const details = this.parseJwt(this.$store.state.token)
      const expiration = new Date(details.exp * 1000)
      if(expiration < new Date()) {
        this.$store.dispatch('changeToken', '')
        this.$store.dispatch('changeSub', '')
        this.$store.dispatch('changePoints', 0)
      }
    }
  },
  mounted() {
    this.scrollToTop()
    window.addEventListener('resize', () => {
      if(window.innerWidth > 900) {
        this.showAll()
      }
    });
    setInterval(() => {
      if(window.innerWidth < 900) {
        this.traslate()
      }
    }, 4000)
  },
  data: function() {
    return {
      fakeFacts:[
        this.$t("us.fakesecret1", this.$store.state.locale),
        this.$t("us.fakesecret2", this.$store.state.locale),
        this.$t("us.fakesecret3", this.$store.state.locale),
        this.$t("us.fakesecret4", this.$store.state.locale),
        this.$t("us.fakesecret5", this.$store.state.locale),
        this.$t("us.fakesecret6", this.$store.state.locale),
        this.$t("us.fakesecret7", this.$store.state.locale),
        this.$t("us.fakesecret8", this.$store.state.locale)
      ],
      facts:[
        this.$t("us.secret1", this.$store.state.locale),
        this.$t("us.secret2", this.$store.state.locale),
        this.$t("us.secret3", this.$store.state.locale),
        this.$t("us.secret4", this.$store.state.locale),
        this.$t("us.secret5", this.$store.state.locale),
        this.$t("us.secret6", this.$store.state.locale),
        this.$t("us.secret7", this.$store.state.locale),
        this.$t("us.secret8", this.$store.state.locale)
      ],
      factCount: 0,
      data:0
    };
  },
  methods: {
    async checkValueAndSend(points) {
      if(points > this.$store.state.points && this.$store.state.token !== null && this.$store.state.token !== ''){
        const url = this.baseUrl + "game/setpoints"
        const data = {
          points: Number(points)
        }
        const response = await fetch(url, {
          method: 'POST',
          mode: 'cors',
          cache: 'no-cache',
          credentials: 'same-origin',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.$store.state.token,
          },
          redirect: 'follow',
          referrerPolicy: 'no-referrer',
          body: JSON.stringify(data)
        });
        if(response.ok && response.status === 200){
          this.$store.dispatch('changePoints', points)
        }
      }
    },
    toggleLeaderboard() {
      if(this.$refs.leaderboard.style.display === 'none') {
        this.$refs.leaderboardComp.fetchLeaderboard()
        this.$refs.leaderboard.style.display = 'flex'
      } else {
        this.$refs.leaderboard.style.display = 'none'
      }
    },
    toggleLogIn() {
      if(this.$refs.login.style.display === 'none') {
        this.$refs.login.style.display = 'flex'
      } else {
        this.$refs.login.style.display = 'none'
      }
    },
    logout() {
      this.$store.dispatch('changeToken', '')
      this.$store.dispatch('changePoints', 0)
      this.$store.dispatch('changeSub', '')
    },
    toggleRegister() {
      if(this.$refs.register.style.display === 'none') {
        this.$refs.register.style.display = 'flex'
      } else {
        this.$refs.register.style.display = 'none'
      }
    },
    addFact(points) {
      if(points/100 > this.factCount) {
        this.factCount = this.factCount + 1
      }
    },
    restoreFact() {
      const points = this.$store.state.points
      this.factCount = Math.floor(points / 100)
    },
    resetFact() {
      this.factCount = 0
    },
    showAll() {
      this.data = 0
      this.$refs.img1.style.visibility = `visible`
      this.$refs.img1.style.opacity = 1
      this.$refs.img2.style.visibility = `visible`
      this.$refs.img2.style.opacity = 1
      this.$refs.img3.style.visibility = `visible`
      this.$refs.img3.style.opacity = 1
    },
    traslate() {
      this.data++
      //let width = window.innerWidth + 50
      if(this.data === 1){
        this.$refs.img3.style.visibility = `hidden`
        this.$refs.img3.style.opacity = 0
        //this.$refs.img1.style.display = "block"
        this.$refs.img1.style.visibility = `visible`
        this.$refs.img1.style.opacity = 1
      }
      if(this.data === 2){
        //this.$refs.img1.style.display = "none"
        this.$refs.img1.style.visibility = `hidden`
        this.$refs.img1.style.opacity = 0
        //this.$refs.img2.style.display = "block"
        this.$refs.img2.style.visibility = `visible`
        this.$refs.img2.style.opacity = 1
      }
      if(this.data === 3){
        //this.$refs.img2.style.display = "none"
        this.$refs.img2.style.visibility = `hidden`
        this.$refs.img2.style.opacity = 0
        this.data = 0
        this.$refs.img3.style.visibility = `visible`
        this.$refs.img3.style.opacity = 1
      }
    }
  },
  computed: {
    isLittle() {
      return window.innerWidth < 900
    }
  }
}
</script>

<style lang="sass">
@use "../assets/scss/_variables.sass"
.about-us-intro
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  .about-us-intro-paragraph
    margin-bottom: 0
    width: 50%
  .about-us-intro-login
    margin-bottom: 2%
    width: 50%
    a
      color: variables.$primary-orange !important
      font-family: variables.$opensans-semibolditalic
  .login-game-container
    width: 50%
    display: flex
    justify-content: space-evenly
    margin: 1% 0
    p
      font-size: 1rem
      a
        color: variables.$primary-orange !important
        font-family: variables.$opensans-semibolditalic
      span
        font-family: variables.$opensans-semibold
        color: variables.$primary-font-color
  button
    background-color: transparent
    font-family: variables.$opensans-italic !important
    color: variables.$primary-orange
    border: variables.$primary-orange solid 1px
    padding: 0.5% 1% 0.5% 1%
    cursor: pointer

.about-us-container
  display: flex
  justify-content: center
  .about-us-info-item
    width: 60%
    .fact-container
      display: flex
      margin-bottom: 4%
      &:nth-of-type(odd)
        justify-content: flex-start
        .position
          justify-content: flex-start
      &:nth-of-type(even)
        justify-content: flex-end
        .position
          justify-content: flex-end
      .position
        font-family: variables.$opensans-semibold
        letter-spacing: -2px
        min-width: 115px
        display: flex
        align-items: flex-start
        p
          color: variables.$primary-orange !important
          margin: 0
      .fact
        margin: 0
        color: transparent !important
        text-shadow: 0 0 12px #4D5763
        a
          color: transparent !important
          text-shadow: 0 0 12px #4D5763
      .fact-active
        margin: 0
        transition: color 4s ease, text-shadow 0.25s ease
        a
          transition: color 4s ease, text-shadow 0.25s ease
          color: variables.$primary-orange

.about-us-image
  padding-top: 2%
  margin-bottom: 0
  background-image: url("../assets/sodo_footer_flowers.jpg")
  background-size: auto 260px
  background-repeat: no-repeat
  background-position-y: bottom
  background-position-x: center
  height: 200px
  display: flex
  justify-content: center
  .image-container
    display: flex
    justify-content: space-between
    width: 50%
    img
      height: 280px
      border: 25px solid white
      margin-bottom: -15%

.about-us-base
  height: 150px
  width: 100%
  background-color: variables.$primary-light-orange

.modal
  position: fixed
  z-index: 1
  left: 0
  top: 0
  width: 100%
  height: 100%
  overflow: hidden
  background-color: rgb(255, 255, 255, 0.8)
  justify-content: center
  align-items: center
  .leaderboard-content
    background-color: transparent !important
  .modal-content
    display: flex
    justify-content: center
    align-items: center
    background-color: white
    padding: 2%
    width: 30%
    button
      background-color: variables.$primary-orange
      border: none
      color: variables.$secondary-font-color
      text-align: center
      text-decoration: none
      padding: 2% 5% 2% 5%

@media screen and (max-width: 1550px)
  .about-us-image
    .image-container
      width: 60%

@media screen and (max-width: 1400px)
  .modal
    .modal-content
      width: 50%

@media screen and (max-width: 1350px)
  .about-us-image
    .image-container
      width: 80%

@media screen and (max-width: 1000px)
  .about-us-image
    background-size: auto 200px
    .image-container
      width: 90%

  .modal
    .modal-content
      width: 60%

@media screen and (max-width: 900px)
  .about-us-image
    .image-container
      position: relative
      width: 245px
      height: 170px
      img
        transition: visibility 0.4s ease-out
        transition: opacity 0.4s ease-out
        position: absolute
        top: 0
        left: 0
        border: 20px solid white

@media screen and (max-width: 800px)
  .about-us-container
    .about-us-info-item
      .fact-container
        .position
          min-width: 64px

  .modal
    .modal-content
      width: 80%

@media screen and (max-width: 650px)
  .about-us-intro
    p
      width: 65%
  .about-us-container
    .about-us-info-item
      width: 70%

  .about-us-image
    .image-container
      img
        border: 10px solid white

@media screen and (max-width: 600px)
  .about-us-image
    background-size: auto 160px

@media screen and (max-width: 500px)
  .about-us-intro
    p
      width: 75%
    .login-game-container
      width: 90%
      flex-direction: column
      align-items: center
  .about-us-container
    .about-us-info-item
      width: 85%
      div
        .position
          min-width: 24%

  .modal
    .modal-content
      width: 85%
</style>