<template>
  <div class="travel-container">
    <div class="travel-intro-container">
      <div class="travel">
        <h1>VIAGGIO</h1>
      </div>
      <div class="travel-intro">
        <p class="travel-desc">In questa pagina abbiamo raccolto per voi alcuni metodi per raggiungere la location del matrimonio. Potete organizzare il vostro viaggio in maniera indipendente oppure, in base ai vostri piani: durata del soggiorno, destinazioni successive, date di arrivo, possiamo aiutarvi ad organizzare il viaggio. In tal caso, per favore, durante la registrazione nella pagina "PARTECIPAZIONE" segnalate la volontà di essere aiutati.</p>
      </div>
    </div>
    <div class="travel-methods-container">
      <div class="travel-map">
        <img src="../assets/map_europa.jpg">
      </div>
      <TravelMethod title="AEREO" desc="In aereo è possibile arrivare a Budapest con facilità. Il viaggio è molto veloce, circa 1 ora e 20 miuti, ed è possibile trovare biglietti a prezzi molto economici. Una volta atterrati a Budapest è molto facile raggiungere la location del matrimonio con il treno, transfer (che possiamo prenotare per voi) o noleggiando un automobile." info="Potete cercare i voli con le seguenti compagnie"/>
      <TravelMethod title="AUTO" desc="In auto è veramente comodo raggiungere Debrecen! Il viaggio è abbastanza lungo ma per tutto il percorso sarete sull'autostrada e nei confini dell'area Schengen perciò non esistono dogane ne controlli (oltre ad eventuali controlli causa COVID). È importante ricordarsi alcune cose: Prima di entrare in Slovenia e Ungheria bisognerà pagare il bollino autostradale Sloveno e quello Ungherese. Il cui costo è veramente basso. Un'ulteriore informazione importante è che in Ungheria, a differenza dell'Italia, la tolleranza del tasso alcolemico alla guida è 0. Ricordatevi quindi che non è ammesso nemmeno mangiare un cioccolatino al liquore." info="Inserite il vostro indirizzo di partenza per calcolare il percorso del viaggio"/>
      <TravelMethod title="AUTOBUS" desc="È possibile viaggiare anche in autobus. L'unica compagnia di cui siamo a conoscenza è FlixBus, che arriva a Budapest da cui potrete continuare il vostro viaggio con il treno, transfer (che possiamo prenotare per voi) o noleggiando un automobile. Ciò non toglie la possibilità di organizzarvi in gruppi e di noleggiare un autobus dall'Italia. Probabilmente per via della pandemia le aziende che offrono questo servizio proporranno dei prezzi speciali!" info="Potete cercare i bus con destinazione Budapest al seguente link"/>
      <TravelMethod title="TRENO" desc="Un'altra opzione è il treno. Sebbene non abbiamo esperienze con questo tipo di viaggio, sappiamo che molte persone approfittano dei prezzi vantaggiosi offerti." info="Potete cercare i treni con destinazione Budapest al seguente link"/>
    </div>
    <div class="travel-note">
      <p>Ricordatevi di farci sapere come e quando arrivate e per qualsiasi domanda non esitate a contattarci! Al momento, per quanto riguarda voli, autobus o treni non è possibile trovare molte opzioni a causa della pandemia. Siamo però confidenti che con l'arrivo dell'estate e l'apertura dei paesi sarà possibile trovare molte soluzioni a prezzi vantaggiosi!</p>
    </div>
  </div>
</template>

<script>
import TravelMethod from "@/components/TravelMethod";

export default {
  name: 'Travel',
  components: {TravelMethod},
  mounted() {
    this.scrollToTop()
  }
}
</script>

<style lang="sass">
@use "../assets/scss/_variables.sass"

.travel-container
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  .travel-intro-container
    display: flex
    width: 60%
    margin: 4% 0 4% 0
    .travel
      border-right: variables.$primary-orange solid 2px
      display: flex
      align-items: center
      padding-right: 4%
    .travel-intro
      display: flex
      flex-direction: column
      padding-left: 4%
      .travel-desc
        margin-top: 0
  .travel-methods-container
    display: flex
    flex-direction: column
    align-items: center
    width: 60%
    .travel-map
      width: 600px
      img
        width: 100%
        height: auto
  .travel-note
    width: 100%
    margin: 0
    background-image: url("../assets/sodo_footer_flowers.jpg")
    background-size: auto 260px
    background-repeat: no-repeat
    background-position-y: bottom
    background-position-x: center
    display: flex
    justify-content: center
    p
      width: 80%
      margin: 4% 0 8% 0

@media screen and (max-width: 1000px)
  .travel-container
    .travel-intro-container
      width: 80%
    .travel-methods-container
      width: 80%
    .travel-note
      background-size: auto 200px

@media screen and (max-width: 700px)
  .travel-container
    .travel-intro-container
      flex-direction: column
      align-items: center
      .travel
        border-right: none
        border-bottom: variables.$primary-orange solid 2px
        padding: 0
      .travel-intro
        margin-top: 10%
    .travel-methods-container
      .travel-map
        width: 100%

@media screen and (max-width: 600px)
  .travel-container
    .travel-note
      background-size: auto 160px

@media screen and (max-width: 500px)
  .travel-container
    .travel-intro-container
      .travel-intro
        .travel-link
          flex-direction: column
          align-items: center
          margin-top: 2%
    .travel-methods-container
      .travel-map
        width: 100%
</style>
