<template>
  <div class="infopage-container">
    <div class="infopage-intro-container">
      <div class="infopage">
        <h1>{{ this.$t("info.title", this.$store.state.locale) }}</h1>
      </div>
      <div class="infopage-intro">
        <p class="infopage-desc">{{ this.$t("info.intro", this.$store.state.locale) }}</p>
        <div class="infopage-link" v-if="this.$store.state.locale === 'it'">
          <router-link class="travel" :to="{path: `/it/info/travel`}">VIAGGIO</router-link>
          <router-link class="accommodation" :to="{path: `/it/info/accommodation`}">ALLOGGIO</router-link>
          <router-link class="what-to-do" :to="{path: `/it/info/vacation`}">COSA FARE</router-link>
        </div>
      </div>
    </div>
    <div class="question-container">
      <Question v-for="questionData in getQuestionData()" v-bind:key="questionData" :question="questionData.question" :answer="questionData.answer"/>
    </div>
  </div>
</template>

<script>
import Question from "@/components/Question";
import QuestionsData from "@/models/QuestionsData";
export default {
  name: 'Accommodation',
  components: {Question},
  mounted() {
    this.scrollToTop()
  },
  methods: {
    getQuestionData: function (){
      return QuestionsData
    }
  }
}
</script>

<style lang="sass">
@use "../assets/scss/_variables.sass"

.infopage-container
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  background-image: url("../assets/sodo_footer_flowers.jpg")
  background-size: auto 260px
  background-repeat: no-repeat
  background-position-y: bottom
  background-position-x: center
  .infopage-intro-container
    display: flex
    width: 60%
    margin: 4% 0 4% 0
    .infopage
      border-right: variables.$primary-orange solid 2px
      display: flex
      align-items: center
      padding-right: 4%
    .infopage-intro
      display: flex
      flex-direction: column
      padding-left: 4%
      .infopage-desc
        margin-top: 0
      .infopage-link
        display: flex
        justify-content: space-around
        a
          font-family: variables.$opensans-italic
          color: variables.$primary-orange
          text-decoration: none
          border: variables.$primary-orange solid 1px
          padding: 0.5% 2% 0.5% 2%
.question-container
  margin-bottom: 0
  padding-bottom: 10%
  display: flex
  flex-direction: column
  align-items: center

@media screen and (max-width: 1000px)
  .infopage-container
    background-size: auto 200px
    .infopage-intro-container
      width: 80%

@media screen and (max-width: 900px)
  .question-container
    margin-bottom: 0
    padding-bottom: 16%

@media screen and (max-width: 700px)
  .infopage-container
    .infopage-intro-container
      flex-direction: column
      align-items: center
      .infopage
        border-right: none
        border-bottom: variables.$primary-orange solid 2px
        padding: 0
      .infopage-intro
        margin-top: 10%

@media screen and (max-width: 600px)
  .infopage-container
    background-size: auto 160px

@media screen and (max-width: 500px)
  .infopage-container
    .infopage-intro-container
      .infopage-intro
        .infopage-link
          flex-direction: column
          align-items: center
          margin-top: 2%
          .accommodation
            margin: 8% 0 8% 0
</style>
