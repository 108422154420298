<template>
  <div class="gallery-content">
    <div class="gallery-content-video">
      <h2>{{ this.$t("gallery.video", this.$store.state.locale) }}</h2>
      <h4>Trailer</h4>
      <iframe title="vimeo-player" src="https://player.vimeo.com/video/583128307?h=a096153ce2" width="1280" height="466" frameborder="0" allowfullscreen></iframe>
      <hr>
      <h4>{{ this.$t("gallery.videoc", this.$store.state.locale) }}</h4>
      <iframe title="vimeo-player" src="https://player.vimeo.com/video/640339121?h=39a4049a79" width="1280" height="466" frameborder="0" allowfullscreen></iframe>
    </div>
    <div class="gallery-content-foto">
      <h2>{{ this.$t("gallery.foto", this.$store.state.locale) }}</h2>
      <div class="gallery-content-album">
        <router-link class="petraandrea" :to="{path: `/it/gallery/creative`}">
          <img src="../assets/galleria_petraandrea.svg">
        </router-link>
        <router-link class="cerimonia" :to="{path: `/it/gallery/cerimonia`}">
          <img ref="cerimonia">
        </router-link>
        <router-link class="ricevimento" :to="{path: `/it/gallery/ricevimento`}">
          <img ref="ricevimento">
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import cerimoniaHu from "@/assets/galleria_cerimonia_hu.svg";
import ricevimentoHu from "@/assets/galleria_ricevimento_hu.svg";
import cerimoniaIt from "@/assets/galleria_cerimonia_it.svg";
import ricevimentoIt from "@/assets/galleria_ricevimento_it.svg";

export default {
  name: "GalleryContent",
  mounted() {
    this.mountImageLocale()
  },
  methods: {
    mountImageLocale() {
      if(this.$store.state.locale === 'hu') {
        this.$refs.cerimonia.src = cerimoniaHu
        this.$refs.ricevimento.src = ricevimentoHu
      } else {
        this.$refs.cerimonia.src = cerimoniaIt
        this.$refs.ricevimento.src = ricevimentoIt
      }
    },
  }



}
</script>

<style lang="sass">
.gallery-content
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  width: 80%
  margin-bottom: 5%

.gallery-content-video
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  width: 100%
  margin-top: 2%

.gallery-content-foto
  display: flex
  flex-direction: column
  justify-content: space-evenly
  width: 100%
  margin-top: 5%
  .gallery-content-album
    display: flex
    justify-content: space-evenly
    width: 100%
    a
      width: 18%

@media screen and (max-width: 1200px)
  iframe
    width: 100%
    height: 100%

@media screen and (max-width: 600px)
  .gallery-content-album
    flex-direction: column
    align-items: center
    a
      width: 50% !important
      margin-bottom: 5%
</style>