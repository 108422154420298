<template>
  <div  id="main-frame-error" class="game-wrapper">
  </div>
</template>

<script>
import {Runner} from "@/game/Runner"

export default {
  name: 'Runner',
  props: ['points'],
  mounted: function () {
    console.log("mounted")
    new Runner().initGame(this.points)
  },
  updated: function() {
    new Runner().setPoints(this.points)
  },
  unmounted: function () {
    console.log("unmounted")
    new Runner().totalStop()
  }
}
</script>

<style lang="sass">
body
  .game-wrapper
    color: #2b2b2b
    font-size: 1em
    line-height: 1.55
    margin: 0 auto
    max-width: 600px
    width: 100%
    position: relative //this is for making the controller on touch devices only on the area game
  .runner-container
    height: 150px
    max-width: 600px
    overflow: hidden
    width: 44px
  .runner-canvas
    height: 150px
    max-width: 600px
    opacity: 1
    overflow: hidden
    z-index: 2
  .controller
    background: rgba(247, 247, 247, .1)
    height: 100%
    left: 0
    position: absolute
    top: 0
    width: 100%
    z-index: 1

@media (max-width: 420px)
  .suggested-left > #control-buttons, .suggested-right > #control-buttons
    float: none

@media (max-height: 350px)
  h1
    margin: 0 0 15px

  .game-wrapper
    margin-top: 5%

@media (min-width: 600px) and (max-width: 736px) and (orientation: landscape)
  .game-wrapper
    margin-left: 0
    margin-right: 0

@media (min-width: 420px) and (max-width: 736px) and (min-height: 240px) and (max-height: 420px) and (orientation: landscape)
  .game-wrapper
    margin-bottom: 100px

@media (min-height: 240px) and (orientation: landscape)
  .game-wrapper
    margin-bottom: 90px


@media (max-height: 320px) and (orientation: landscape)
  .runner-container
    top: 10px

@media (max-width: 240px)
  .game-wrapper
    overflow: inherit
    padding: 0 8px
</style>