<template>
  <div class="gallery-header">
    <div class="gallery-header-container">
      <h1>GALLERY</h1>
    </div>
  </div>
  <div class="gallery-container" v-if="validViewer">
    <GalleryContent/>
  </div>
  <div class="gallery-container" v-else>
    <div class="gallery-login">
      <LogIn :psw-only=true />
    </div>
  </div>
</template>

<script>
import LogIn from "@/components/LogIn";
import GalleryContent from "@/components/GalleryContent";

export default {
  name: "Gallery",
  components: {LogIn, GalleryContent},
  created() {
    if(this.$store.state.fotoToken !== null && this.$store.state.fotoToken.length > 0){
      const details = this.parseJwt(this.$store.state.fotoToken)
      const expiration = new Date(details.exp * 1000)
      if(expiration < new Date()) {
        this.$store.dispatch('changeFotoToken', '')
      }
    }
  },
  async mounted() {
    this.scrollToTop()
    await this.isTokenPresent()
  },
  data: function() {
    return {
      validViewer: false
    };
  },
  methods: {
    async isTokenPresent() {
      if(this.$store.state.fotoToken !== null && this.$store.state.fotoToken.length > 0){
        const url = this.baseUrl + "gallery/valid"
        const response = await fetch(url, {
          method: 'GET',
          mode: 'cors',
          cache: 'no-cache',
          credentials: 'same-origin',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.$store.state.fotoToken
          },
          redirect: 'follow',
          referrerPolicy: 'no-referrer'
        });
        if(response.ok && response.status === 200){
          this.validViewer = true
        }
      }
    }
  }
}
</script>

<style lang="sass">
.gallery-header
  display: flex
  justify-content: center
  align-items: center
  .gallery-header-container
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    width: 80%

.gallery-container
  display: flex
  justify-content: center
  align-items: center
.gallery-login
  width: 25%
  margin: 5%

@media screen and (max-width: 1200px)
  .gallery-login
    width: 50% !important
    margin: 5%

@media screen and (max-width: 1200px)
  .gallery-login
    width: 70% !important
    margin: 5%
</style>