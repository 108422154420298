<template>
  <div class="dafuq">
    <p></p>
    <SlideShow cells="35" :items="images"/>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'
import SlideShow from "@/components/SlideShow";

export default {
  name: 'Album',
  components: {SlideShow},
  mounted() {
    this.scrollToTop()
    this.fetchAlbumImages()
  },
  methods: {
    async fetchAlbumImages() {
      const url = this.baseUrl + "album/" + this.$route.params.name
      const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.$store.state.fotoToken
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer'
      });
      if(response.ok && response.status === 200){
        this.images = await response.json()
      } else {
        window.location.reload();
      }
    }
  },
  data() {
    return {
      images: [],
    }
  }
}
</script>
