<template>
  <footer />
</template>

<script>
export default {
  name: 'Menu'
}
</script>

<style lang="sass">
@use "../assets/scss/_variables.sass"
footer
  background-color: variables.$secondary-orange
  width: 100%
  height: 60px
</style>
