export default {
    menu: {
        participation: "RÉSZVÉTEL",
        gallery: "GALÉRIA",
        us: "RÓLUNK",
        location: "HELYSZÍN",
        info: "INFO",
    },
    home: {
        //participate: "RÉSZTVESZEK",
        gallery: "GALÉRIA",
        locationtitle: "Helyszín",
        location: "A Park Hill egy kilenc hektáros park, Debrecentől 5 km-re egy erdő közepén. Azért választottuk ezt a helyszínt az esküvőnkhöz, mert távol van a város zajától egy gyönyörű zöld környezetben, ugyanakkor nagyon könnyen megközelíthető. A ceremónia lebonyolítására a szabadban egy gyönyörű kovácsoltvas pavilon áll rendelkezésre, míg a vacsora egy elegáns, 800 nm-es sátorban lesz.",
        locationdiscover: "Olvass tovább",
        programtitle: "Program",
        //counter1: "Már csak",
        counter1: "Már",
        counter2: "NAP",
        //counter3: "van hátra, hogy együtt ünnepeljünk!"
        counter3: "telt el azóta, hogy együtt ünnepeltünk!"
    },
    participate: {
        title: "Ünnepelj velünk",
        intro1: "Reméljük, hogy számíthatunk Rád ezen a különleges napon.",
        intro2: "Kérjük, hogy jelezd vissza a részvételi szándékod",
        intro3: "2021. június 1-ig.",
        name: "Név:",
        addguest: " További vendégek",
        food: "Van-e valamilyen kérésed az étkezéssel kapcsolatban?",
        other: "Van-e valamilyen egyéb kérésed vagy kérdésed?",
        send: "KÜLDÉS",
        successtext1: "Köszönjük, hogy visszajelezted a részvételi szándékod! Reméljük, hamarosan együtt ünnepelhetünk.",
        successtext2: "Addig is, miért nem játszol egy kis ",
        successquestion: "-t?",
        successbutton: "A JÁTÉKHOZ"
    },
    us: {
        title: "A TOMATOES CSALÁD",
        intro: 'Készítettünk egy kis játékot Nektek. Segítsetek Sodinak átugrani a virágágyások felett és megtudhattok néhány „titkos információt” rólunk. A szabályok egyszerűek, az ugráshoz használjátok a szóközt, míg a lefele nyíllal le tudtok hajolni, hogy el tudjátok kerülni a felülről érkező akadályokat. A játék egyben verseny is, aki legtovább jut el a #SODORun-ban az esküvő napjáig, egy kis jutalmat kap.',
        race: 'A játékban való részvételhez ',
        register: "regisztráljatok",
        connector: "és",
        access: "jelentkezzetek be",
        logout: "Kijelentkezés",
        welcome: "Szia",
        points: "a rekordod:",
        leaderboard: "NÉZD MEG A RANGSORT",
        fakesecret1: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean blandit malesuada libero, vehic",
        fakesecret2: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean blandit malesuada libero, vehicula p",
        fakesecret3: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean blandit malesuada libero, vehicula pretium massa fringilla sed. In aliquet, dolor vitae molestie volutpat, quam nibh sodales mauris, at",
        fakesecret4: "Lorem ipsum dolor sit amet, consectetur adipiscin",
        fakesecret5: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean blandit malesuada libero",
        fakesecret6: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean blandit malesuada libero, vehicula pretium massa fringilla sed. In",
        fakesecret7: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean blandit malesuada libero, vehicula pretium massa fringilla sed. In aliquet, dolor vitae",
        fakesecret8: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean blandit malesuada",
        secret1: "A kapcsolatunk előtt igazi jó barátok voltunk (Andrea vs Friendzone 1-0)",
        secret2: "Nemrég ünnepeltük a 8. évfordulónkat. Igen, minden a távoli 2013. évben kezdődött.",
        secret3: "Andrea első magyar szavai a benzineskanna, szempillaspirál és gémkapocs voltak, melyeket egy tablet játék kapcsán tanult meg. Igazán hasznosak, ugye? :)",
        secret4: "2015-ben érkezett hozzánk a mi kis Sodónk.",
        secret5: "Már hat éve próbálunk kirakni egy 4000 darabos puzzle-t. Higgyétek el, nem egyszerű!",
        secret6: "Az együtt töltött évek alatt nagyon sokat utaztunk autóval, hogy Sodó is velünk jöhessen. Nagyjából 135.253 km-t tettünk meg hármasban.",
        secret7: "Van egy érdekes gyűjteményünk. Minden lakásból, ahol eddig albérletben laktunk, elcsentünk valami apró kis emléket. ;)",
        secret8: "2016 nyarán az IKEA bemutatott egy nagyon vicces reklámot. Egy férfi kinn reked a teraszon és ott kell átvészelnie ezt az időszakot az IKEA felszereléseivel. A reklám dalát nagyon megszerettük és egész nap ezt énekeltük \"Don't touch me Tomato\". Nemsokál megszülettek az új beceneveink és innentől elkezdtük Tomatónak, Tomatának és Tomitónak (Sodó) hívni magunkat. Íme a reklám: ",
        backbutton: "VISSZA",
    },
    leaderboard: {
        leaderboard: "RANGSOR",
        name: "NÉV",
        points: "EREDMÉNY"
    },
    register:{
        title: "REGISZTRÁCIÓ",
        name: "Név:",
        placeholdername: "Írd be a neved",
        phoneemail: "E-mail cím vagy telefonszám:",
        placeholderphoneemail: "Írd be az e-mail címed vagy a telefonszámod",
        pass: "Jelszó:",
        placeholderpass: "Válassz egy jelszót:",
        repeatpass: "Jelszó ismétlése:",
        placeholderrepeatpass: "Ismételd meg a jelszót",
        register: "KÜLDÉS",
        success: "Sikeresen regisztráltál!"
    },
    login:{
        title: "BEJELENTKEZÉS",
        name: "Név:",
        placeholdername: "Írd be a neved",
        pass: "Jelszó:",
        placeholderpass: "Írd be a jelszavad",
        login: "BEJELENTKEZÉS"
    },
    location: {
        intro: "Ahogyan már korábban írtuk, a helyszín egy erdőben található. Nem ritka, hogy mókusokkal és más, a magyar erdőkben fellelhető kisállatokkal találkozhatunk. A parkba egy fákkal övezett bejáraton át tudunk bejutni, ahol egy vendégváró fogadással üdvözöljük az érkezőket. Kicsivel beljebb található a ceremónia tágas helyszíne. A kovácsoltvas pavilon előtt székek vannak, így kényelmesen ülve lehet követni a szertartást. A gyerekek számára van egy kis játszótér, ezen kívül gyönyörű lovakkal is találkozhattok. A vacsora és a mulatság egy légkondicionált sátorban lesz. Ha többet szeretnétek megtudni a helyszínről, látogassátok meg az alábbi honlap galériáját.",
        addresstop: "4251, Hajdúsámson - Martinka,",
        addressbottom: "Kastély utca 2.",
        howto: "MEGKÖZELÍTÉS",
        cartitle: "AUTÓVAL",
        taxititle: "TAXIVAL",
        bustitle: "BUSSZAL",
        car: "nagyon egyszerűen megközelíthető a helyszín, mert közel van a városhoz. Ingyenes parkoló áll a vendégek rendelkezésére. Részletesebb információért írjátok be itt alul az indulási címet és keressétek a leggyorsabb útvonalat.",
        taxi: "a taxik megrendelhetők telefonon vagy telefonos applikáción keresztül. Számos taxiállomás van a városban is.",
        bus: "kívánság szerint autóbuszos transzfert szervezünk mind a szertartás előtt, mind az ünnepség végén. Jelezzétek, ha erre igényt tartotok.",
        findpath: "KERESÉS"
    },
    info: {
        title: "INFORMÁCIÓK",
        intro: "Összegyűjtöttünk az eseménnyel kapcsolatban néhány gyakori kérdést és választ. Ha a honlapon lévő információkon kívül bármilyen kérdésetek van, keressetek bennünket!",
    },
    questions: {
        question1: "Módosulhat-e az időpont a koronavírus járvány miatt?",
        answer1: "Szeretnénk mindenképpen megtartani az esküvőnket ezen a napon, természetesen alkalmazkodva az éppen érvényben lévő szabályokhoz. ",
        question2: "Van-e dresscode?",
        answer2: "Egy könnyed nyári mulatságot tervezünk, ahol a legfontosabb, hogy jól érezzétek magatokat, így nincs különösebb szabály. A tánccipőt azonban semmiképpen se felejtsétek otthon. ;)",
        question3: "Lesz-e külön menü a speciális étrendet követőknek?",
        answer3: "Igen, amennyiben van valamilyen különleges igényed kérjük, jelezd a részvételed visszaigazolásakor.",
        question4: "Lesz-e menyasszonytánc?",
        answer4: "Mivel a más országokból érkező vendégeink számára ez egy ismeretlen hagyomány, így inkább úgy döntöttünk, hogy nem tartunk menyasszonytáncot.",
        question5: "Van-e ajándék lista?",
        answer5: "Nincs, számunkra a jelenlétetek a legnagyobb ajándék. Azonban, ha szeretnétek minket meglepni valamivel, hozzájárulhattok a nászutunk költségeihez.",
        question6: "Lesz-e szállás a helyszínen?",
        answer6: "Nincs, azonban a helyszín könnyen megközelíthető, így gyorsan Debrecenbe tudtok jutni."
    },
    game: {
        init: "Nyomd meg a SZÓKÖZ billentyűt vagy ÉRINTSD MEG a játékot a kezdéshez"
    },
    errors: {
        name: "Túl rövid a név. Minimum 2 karakter hosszúnak kell lennie",
        username: "Túl rövid felhasználónév. Minimum 2 karakter hosszúnak kell lennie",
        usernamenotavailable: "Ez a felhasználónév már foglalt, kérlek válassz másikat",
        email: "Az e-mailcím túl rövid. Minimum 4 karakter hosszúnak kell lennie.",
        emailnotvalid: "Az e-mail cím vagy telefonszám érvénytelen",
        emailnotavailable: "Az e-mail cím vagy telefonszám már foglalt, kérlek válassz másikat ",
        password: "A password túl rövid. Minimum 4 karakter hosszúnak kell lennie.",
        passwordnotvalid: "A jelszavak nem egyeznek"
    },
    gallery: {
        video: "FILM",
        videoc: "Film",
        foto: "FOTÓ"
    }
}
