<template>
  <div class="travel-method">
    <div class="travel-title">
      <img class="travel-icon" :src="require(`../assets/${title.toLowerCase()}.svg`)"><h3>{{ title }}</h3>
    </div>
    <div class="travel-method-desc">
      <p class="travel-method-text">{{ desc }}</p>
      <div class="travel-method-link-cont" v-if="title.toLowerCase() === 'auto'">
        <p class="travel-method-link-note">{{ info }}</p>
        <div class="travel-method-search" >
          <input type="text" id="travel-dest" name="travel-dest" v-model="startPoint">
          <button v-on:click="goToGmaps()">CERCA PERCORSO</button>
        </div>
      </div>
      <div class="travel-method-link-cont" v-else>
        <p class="travel-method-link-note">{{ info }}</p>
        <div class="travel-method-links" >
          <a v-for="link in getLinks()"  v-bind:key="link" :href="link.link" target="_blank">{{link.name}}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TravelMethod",
  props: ['title', 'desc', 'info'],
  data: function () {
    return {
      startPoint: "",
      typesData: {
        autobus: {
          links: [{
            name: 'FlixBus',
            link: 'https://www.flixbus.it/'
          }]
        },
        aereo: {
          links: [{
            name: 'WizzAir',
            link: 'https://wizzair.com/'
          },
          {
            name: 'Ryanair',
            link: 'https://www.ryanair.com/'
          },
          {
            name: 'Alitalia',
            link: 'https://www.alitalia.com/'
          }]
        },
        treno: {
          links: [{
            name: 'Trenitalia',
            link: 'https://www.trenitalia.com/'
          },
          {
            name: 'MAV',
            link: 'https://jegy.mav.hu/en'
          }]
        }
      }
    }
  },
  methods: {
    getLinks: function () {
      return this.typesData[this.title.toLowerCase()].links
    },
    goToGmaps: function () {
      let link = `https://maps.google.com?saddr=${this.startPoint.replace(" ", "+")}&daddr=Park+Hill+rendezv%C3%A9nyhelysz%C3%ADn`
      Object.assign(document.createElement('a'), {
        target: '_blank',
        href: link,
      }).click();
    }
  }
}
</script>

<style lang="sass">
@use "../assets/scss/_variables.sass"

.travel-method
  display: flex
  flex-direction: column
  align-items: center
  margin-bottom: 3%
  .travel-title
    display: flex
    justify-content: flex-start
    align-items: center
    width: 100%
    .travel-icon
      width: 10%
      height: auto
  .travel-method-desc
    align-self: flex-end
    display: flex
    flex-direction: column
    align-items: center
    width: 90%
    .travel-method-text
      text-align: justify
      margin: -2% 0 1% 2%
    .travel-method-link-cont
      display: flex
      flex-direction: column
      align-items: center
      padding-bottom: 4%
      border-bottom: variables.$primary-orange solid 1px
      width: 80%
      .travel-method-link-note
        width: 70%
        font-family: variables.$opensans-italic
      .travel-method-links
        width: 100%
        display: flex
        justify-content: space-around
        a
          font-family: variables.$opensans-italic
          color: variables.$primary-orange
          text-decoration: none
          border-bottom: variables.$secondary-font-color solid 12px
          height: 14px
      .travel-method-search
        width: 80%
        display: flex
        justify-content: space-around
        input[type="text"]
          height: 25px
          border: 1px solid variables.$primary-orange
          background-color: variables.$secondary-font-color
          width: 50%
        button
          background-color: variables.$primary-orange
          border: none
          color: variables.$secondary-font-color
          text-align: center
          text-decoration: none

@media screen and (max-width: 500px)
  .travel-method
    .travel-method-desc
      .travel-method-link-cont
        .travel-method-link-note
          width: 90%
        .travel-method-links
          flex-direction: column
          align-items: center
          a
            margin-bottom: 4%
        .travel-method-search
          flex-direction: column
          align-items: center
          input[type="text"]
            margin-bottom: 6%
          button
            height: 30px
            margin-bottom: 4%
</style>