<template>
  <div class="login-form">
    <div class="close-modal">
      <button class="close-modal-button" v-on:click="emitRegisterClose"><span></span></button>
    </div>
    <fieldset ref="fieldset">
      <legend>{{ this.$t("register.title", this.$store.state.locale) }}</legend>
      <div class="login-container">
        <label for="name">{{ this.$t("register.name", this.$store.state.locale) }}</label>
        <input v-on:change="validateUser" ref="username" type="text" :placeholder="this.$t('register.placeholdername', this.$store.state.locale)" id="name" name="name" required minlength="2" maxlength="100">
        <span></span>
      </div>
      <div class="login-container">
        <label for="email">{{ this.$t("register.phoneemail", this.$store.state.locale) }}</label>
        <input v-on:change="validateEmail" ref="email" type="text" :placeholder="this.$t('register.placeholderphoneemail', this.$store.state.locale)" id="email" name="email" required minlength="4" maxlength="100" pattern="^([0-9]{9})|([A-Za-z0-9._%\+\-]+@[a-z0-9.\-]+\.[a-z]{2,3})$">
        <span></span>
      </div>
      <div class="login-container">
        <label for="psw">{{ this.$t("register.pass", this.$store.state.locale) }}</label>
        <input v-on:change="validatePassword" ref="pass" type="password" :placeholder="this.$t('register.placeholderpass', this.$store.state.locale)" id="psw" name="psw" required minlength="4" maxlength="100">
        <span></span>
      </div>
      <div class="login-container">
        <label for="psw-repeat">{{ this.$t("register.repeatpass", this.$store.state.locale) }}</label>
        <input v-on:change="validateMatchPassword" ref="passcheck" type="password" :placeholder="this.$t('register.placeholderrepeatpass', this.$store.state.locale)" id="psw-repeat" name="psw-repeat" required minlength="4" maxlength="100">
        <span></span>
      </div>
      <div ref="succeded" class="login-succeded" style="display: none">
        <p>{{ this.$t("register.success", this.$store.state.locale) }}</p>
      </div>
      <div ref="buttons" class="login-buttons">
        <button class="back-button" v-on:click="emitRegisterClose">{{ this.$t("us.backbutton", this.$store.state.locale) }}</button>
        <button ref="regButton" class="register-button" v-on:click="submitRegistration">{{ this.$t("register.register", this.$store.state.locale) }}</button>
      </div>
    </fieldset>
  </div>
</template>

<script>
export default {
  name: "Register",
  emits: {
    // No validation
    closeRegister: null,

    // Validate submit event
    submit: ({ email, password }) => {
      if (email && password) {
        return true
      } else {
        console.warn('Invalid submit event payload!')
        return false
      }
    }
  },
  methods: {
    async validateUser() {
      const username = this.$refs.username.value
      if(username.length < 1) {
        const error = this.$t("error.username", this.$store.state.locale)
        this.$refs.username.setCustomValidity(error)
        this.$refs.username.nextElementSibling.textContent = error
        this.$refs.username.reportValidity()
        return false
      }
      const url = this.baseUrl + "account/username/" + this.$refs.username.value
      const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json'
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
      });
      if(!response.ok && response.status !== 200){
        //TODO replace login with message error
      }
      const {available} = await response.json()
      if(!available) {
        const error = this.$t("error.usernamenotavailable", this.$store.state.locale)
        this.$refs.username.setCustomValidity(error)
        this.$refs.username.nextElementSibling.textContent = error
        this.$refs.username.reportValidity()
        return false
      }
      const noerror = ""
      this.$refs.username.setCustomValidity(noerror)
      this.$refs.username.nextElementSibling.textContent = noerror
      this.$refs.username.reportValidity()
      return true
    },
    async validateEmail() {
      const email = this.$refs.email.value
      if(email.length < 4) {
        const error = this.$t("error.email", this.$store.state.locale)
        this.$refs.email.setCustomValidity(error)
        this.$refs.email.nextElementSibling.textContent = error
        this.$refs.email.reportValidity()
        return false
      }

      let regex = /^([0-9]{9,10})|([A-Za-z0-9._%\\+\\-]+@[a-z0-9.\\-]+\.[a-z]{2,3})$/
      if(!regex.test(email)){
        const error = this.$t("error.emailnotvalid", this.$store.state.locale)
        this.$refs.email.setCustomValidity(error)
        this.$refs.email.nextElementSibling.textContent = error
        this.$refs.email.reportValidity()
        return false
      }

      const url = this.baseUrl + "account/email/" + this.$refs.email.value
      const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json'
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
      });
      if(!response.ok && response.status !== 200){
        //TODO remove registration form display unavailable
      }
      const {available} = await response.json()
      if(!available) {
        const error = this.$t("error.emailnotavailable", this.$store.state.locale)
        this.$refs.email.setCustomValidity(error)
        this.$refs.email.nextElementSibling.textContent = error
        this.$refs.email.reportValidity()
        return false
      }
      const noerror = ""
      this.$refs.email.setCustomValidity(noerror)
      this.$refs.email.nextElementSibling.textContent = noerror
      this.$refs.email.reportValidity()
      return true
    },
    validatePassword() {
      const pass = this.$refs.pass.value
      if(pass.length < 4) {
        const error = this.$t("error.password", this.$store.state.locale)
        this.$refs.pass.setCustomValidity(error)
        this.$refs.pass.nextElementSibling.textContent = error
        this.$refs.pass.reportValidity()
        return false
      }
      const noerror = ""
      this.$refs.pass.setCustomValidity(noerror)
      this.$refs.pass.nextElementSibling.textContent = noerror
      this.$refs.pass.reportValidity()
      return true
    },
    validateMatchPassword() {
      const pass = this.$refs.passcheck.value
      if(pass !== this.$refs.pass.value) {
        const error = this.$t("error.passwordnotvalid", this.$store.state.locale)
        this.$refs.passcheck.setCustomValidity(error)
        this.$refs.passcheck.nextElementSibling.textContent = error
        this.$refs.passcheck.reportValidity()
        return false
      }
      const noerror = ""
      this.$refs.passcheck.setCustomValidity(noerror)
      this.$refs.passcheck.nextElementSibling.textContent = noerror
      this.$refs.passcheck.reportValidity()
      return true
    },
    async submitRegistration() {
      const validUser = this.validateUser()
      const validEmail = this.validateEmail()
      const validPassword = this.validatePassword()
      const validPasswordCheck = this.validateMatchPassword()
      if(validUser && validEmail && validPassword && validPasswordCheck){
        const url = this.baseUrl + "register"
        const data = {
          username: this.$refs.username.value,
          email: this.$refs.email.value,
          password: this.$refs.pass.value
        }
        const response = await fetch(url, {
          method: 'POST',
          mode: 'cors',
          cache: 'no-cache',
          credentials: 'same-origin',
          headers: {
            'Content-Type': 'application/json'
          },
          redirect: 'follow',
          referrerPolicy: 'no-referrer',
          body: JSON.stringify(data)
        });
        if(response.ok && response.status === 200){
          const {token} = await response.json()
          this.$store.dispatch('changeToken', token)
          const jwtPayload = this.parseJwt(token)
          this.$store.dispatch('changeSub', jwtPayload.sub)
          this.$store.dispatch('changePoints', jwtPayload.points)
          const loginConts = this.$refs.fieldset.querySelectorAll(".login-container")
          Array.from(loginConts).forEach((login) => {
            login.style.display = 'none'
          })
          this.$refs.succeded.style.display = 'block'
          this.$refs.buttons.style.justifyContent = 'center'
          this.$refs.regButton.style.display = 'none'
        }
      }
    },
    emitRegisterClose() {
      this.$emit("closeRegister")
    }
  }
}
</script>

<style lang="sass">
@use "../assets/scss/_variables.sass"

.login-form
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  width: 90%
  .close-modal
    display: flex
    justify-content: flex-end
    width: 100%
    .close-modal-button
      text-align: start
      background: none !important
      padding: 0 !important
      border: none
      font-family: variables.$opensans-italic !important
      color: variables.$primary-orange
      cursor: pointer
      span
        content: url("../assets/+.svg")
        transform: rotate(45deg)
        margin-bottom: -15px
        width: 15px
        height: auto
  fieldset
    width: 100%
    display: flex
    flex-direction: column
    align-items: center
    border: 2px solid variables.$primary-orange
    padding: 5% 0
    legend
      color: variables.$primary-orange
      font-family: variables.$agatho-light-caps
      font-size: 40px
      padding: 0 2%
      margin-left: 4%
    .login-container
      width: 70%
      display: flex
      flex-direction: column
      align-items: flex-start
      margin-bottom: 5%
      label
        margin-bottom: 2%
      input
        width: 100%
        height: 25px
        border: 1px solid variables.$primary-orange
      input:focus
        outline: none
      span
        font-size: 13px
    .login-buttons
      display: flex
      justify-content: space-between
      width: 70%
      margin-top: 2%
      .back-button
        background: none !important
        border: 1px solid variables.$primary-orange
        color: variables.$primary-orange

@media screen and (max-width: 550px)
  .login-form
    fieldset
      .login-buttons
        display: flex
        flex-direction: column-reverse
        align-items: center
        button
          margin: 2% 0

@media screen and (max-width: 500px)
  .login-form
    fieldset
      legend
        font-size: 30px

@media screen and (max-width: 400px)
  .login-form
    fieldset
      legend
      .login-buttons
        button
          margin: 4% 0
</style>