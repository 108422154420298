<template>
  <div class="question-answer">
    <p class="question">{{ this.$t(`questions.${question}`, this.$store.state.locale) }}</p>
    <p class="answer">{{ this.$t(`questions.${answer}`, this.$store.state.locale) }}</p>
  </div>
</template>

<script>
export default {
  name: "Question",
  props: ['question', 'answer']
}
</script>

<style lang="sass">
@use "../assets/scss/_variables.sass"

.question-answer
  display: flex
  flex-direction: column
  width: 60%
  margin-bottom: 1%
  p
    width: 95%
    text-align: justify
    text-justify: auto
  .question
    align-self: flex-start
    font-family: variables.$opensans-italic
    color: variables.$primary-orange !important
    margin-bottom: 1%
  .answer
    align-self: flex-end
    font-family: variables.$opensans-light
    margin-top: 0

@media screen and (max-width: 1000px)
  .question-answer
    width: 80%

@media screen and (max-width: 500px)
  .question-answer
    .question
      margin-bottom: 2%
</style>