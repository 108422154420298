import { createApp } from 'vue'
import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";
import App from './SODOApp.vue'
import router from './router'
import { createI18n} from 'vue-i18n';
import italian from './i18n/italian'
import magyar from './i18n/magyar'
import { baseUrl } from './config'
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from '@fortawesome/free-solid-svg-icons'
import { dom } from "@fortawesome/fontawesome-svg-core";
import Vue3TouchEvents from "vue3-touch-events";

dom.watch();

library.add(fas);

const messages = {
    it: italian,
    hu: magyar
}

const i18n = createI18n({
    locale: checkDefaultLanguage(),
    fallbackLocale: 'it',
    messages
})

const store = createStore({
    state () {
        return {
            locale: checkDefaultLanguage(),
            points: 0,
            token: '',
            fotoToken: '',
            sub: ''
        }
    },
    mutations: {
        updateLocale(state, newLocale) {
            state.locale = newLocale
        },
        updatePoints(state, newPoints) {
            state.points = newPoints
        },
        updateToken(state, newToken) {
            state.token = newToken
        },
        updateFotoToken(state, newFotoToken) {
            state.fotoToken = newFotoToken
        },
        updateSub(state, newSub) {
            state.sub = newSub
        }
    },
    actions: {
        changeLocale({ commit }, newLocale) {
            i18n.locale = newLocale // important!
            commit('updateLocale', newLocale)
        },
        changePoints({ commit }, newPoints) {
            commit('updatePoints', newPoints)
        },
        changeToken({ commit }, newToken) {
            commit('updateToken', newToken)
        },
        changeFotoToken({ commit }, newFotoToken) {
            commit('updateFotoToken', newFotoToken)
        },
        changeSub({ commit }, newSub) {
            commit('updateSub', newSub)
        }
    },
    plugins: [createPersistedState()]
})

let app = createApp(App)
app.config.globalProperties.baseUrl = baseUrl
app.config.globalProperties.locale = window.navigator.language.split("-")[0]
app.config.globalProperties.scrollToTop = function () {
    window.scrollTo(0,0);
}

app.config.globalProperties.parseJwt = function (token) {
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};
app.component("font-awesome-icon", FontAwesomeIcon).use(Vue3TouchEvents).use(store).use(i18n).use(router).mount('#app')

function checkDefaultLanguage() {
    let matched = null
    let languages = Object.getOwnPropertyNames(messages)
    languages.forEach(lang => {
        if (lang === navigator.language) {
            matched = lang
        }
    })
    if (!matched) {
        languages.forEach(lang => {
            let languagePartials = navigator.language.split('-')[0]
            if (lang === languagePartials) {
                matched = lang
            }
        })
    }
    if (!matched) {
        languages.forEach(lang => {
            let languagePartials = navigator.language.split('-')[0]
            if (lang.split('-')[0] === languagePartials) {
                matched = lang
            }
        })
    }
    return matched
}