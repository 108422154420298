<template>
  <div class="login-form">
    <div class="close-modal" v-if="!pswOnly">
      <button class="close-modal-button" v-on:click="emitLoginClose"><span></span></button>
    </div>
    <fieldset>
      <legend>{{ this.$t("login.title", this.$store.state.locale) }}</legend>
    <div class="login-container" v-if=!pswOnly>
      <label for="name">{{ this.$t("login.name", this.$store.state.locale) }}</label>
      <input ref="username" type="text" :placeholder="this.$t('login.placeholdername', this.$store.state.locale)" id="name" name="name" required>
    </div>
    <div class="login-container">
      <label for="psw">{{ this.$t("login.pass", this.$store.state.locale) }}</label>
      <input ref="pass" type="password" :placeholder="this.$t('login.placeholderpass', this.$store.state.locale)" id="psw" name="psw" required>
    </div>
    <div class="login-buttons">
      <button class="back-button" v-on:click="emitLoginClose">{{ this.$t("us.backbutton", this.$store.state.locale) }}</button>
      <button class="register-button" v-on:click="login">{{ this.$t("login.login", this.$store.state.locale) }}</button>
    </div>
    </fieldset>
  </div>
</template>

<script>
export default {
  name: "LogIn",
  props: {
    pswOnly: {
      type: Boolean,
      default: () => { return false }
    },
  },
  emits: {
    // No validation
    closeLogin: null,
    // No validation
    restoreFact: null
  },
  methods: {
    emitLoginClose() {
      this.$emit("closeLogin")
    },
    async login() {
      if(this.pswOnly){
        if(this.$store.state.fotoToken === null || this.$store.state.fotoToken.length === 0){
          const url = this.baseUrl + "login"
          const data = {
            username: "FotoViewer",
            password: this.$refs.pass.value
          }
          const response = await fetch(url, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
              'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(data)
          });
          if(response.ok && response.status === 200){
            const {token} = await response.json()
            this.$store.dispatch('changeFotoToken', token)
            window.location.reload();
          }
        }
      } else {
        if(this.$store.state.token === null || this.$store.state.token.length === 0){
          const url = this.baseUrl + "login"
          const data = {
            username: this.$refs.username.value,
            password: this.$refs.pass.value
          }
          const response = await fetch(url, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
              'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(data)
          });
          if(response.ok && response.status === 200){
            const {token} = await response.json()
            this.$store.dispatch('changeToken', token)
            const jwtPayload = this.parseJwt(token)
            this.$store.dispatch('changeSub', jwtPayload.sub)
            this.$store.dispatch('changePoints', jwtPayload.points)
            this.$emit("restoreFact")
            this.emitLoginClose()
          }
        }
      }

    }
  }
}
</script>

<style lang="sass">
.register-button
  background-color: #DFA022
  border: none
  color: #FFF5E5
  text-align: center
  text-decoration: none
  padding: 2% 5% 2% 5%
</style>