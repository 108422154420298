<template>
  <div class="accommodation-container">
    <div class="accommodation-intro-container">
      <div class="accommodation">
        <h1>COSA FARE</h1>
      </div>
      <div class="accommodation-intro">
        <p class="accommodation-desc">Questa pagina è in costruzione. Presto sarà possibile trovarvi una raccolta di luoghi e attrazioni da visitare durante la vostra permanenza in Ungheria.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Vacation',
  mounted() {
    this.scrollToTop()
  }
}
</script>

<style lang="sass">

</style>