export class Util {
    /**
     * Default game width.
     * @const
     */
    static DEFAULT_WIDTH = 600;

    /**
     * Frames per second.
     * @const
     */
    static FPS = 60;

    /** @const */
    static IS_HIDPI = true;//window.devicePixelRatio > 1;

    /** @const */
    static IS_IOS = /iPad|iPhone|iPod/.test(window.navigator.platform);

    /** @const */
    static IS_MOBILE = /Android/.test(window.navigator.userAgent) || this.IS_IOS;

    /**
     * Get random number.
     * @param {number} min
     * @param {number} max
     * @param {number}
     */
    static getRandomNum(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    /**
     * Return the current timestamp.
     * @return {number}
     */
    static getTimeStamp() {
        return this.IS_IOS ? new Date().getTime() : performance.now();
    }
}