export default {
    question1: {
        question: "question1",
        answer: "answer1"
    },
    question2: {
        question: "question2",
        answer: "answer2"
    },
    question3: {
        question: "question3",
        answer: "answer3"
    },
    question4: {
        question: "question4",
        answer: "answer4"
    },
    question5: {
        question: "question5",
        answer: "answer5"
    },
    question6: {
        question: "question6",
        answer: "answer6"
    }
}