<template>
  <div class="leaderboard-container">
    <div class="petras-madness">
      <h1>{{ this.$t("leaderboard.leaderboard", this.$store.state.locale) }}</h1>
    </div>
    <div class="close-modal">
      <button class="close-modal-button" v-on:click="emitLeaderboardClose"><span></span></button>
    </div>
    <div class="grid-container">
      <div class="Position"></div>
      <div class="Player">{{ this.$t("leaderboard.name", this.$store.state.locale) }}</div>
      <div class="Points">{{ this.$t("leaderboard.points", this.$store.state.locale) }}</div>
      <div class="column pos">
        <div class="cell position-cell" v-for="(item, index) in this.leaderboard" v-bind:key="index">{{index + 1}}.</div>
      </div>
      <div class="column pla">
        <div class="cell name" v-for="item in this.leaderboard" v-bind:key="item">{{item.username}}</div>
      </div>
      <div class="column poi">
        <div class="cell" v-for="item in this.leaderboard" v-bind:key="item">{{item.points}}</div>
      </div>
    </div>
    <div class="leaderboard-buttons">
      <button class="back-button" v-on:click="emitLeaderboardClose">{{ this.$t("us.backbutton", this.$store.state.locale) }}</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Leaderboard",
  emits: {
    // No validation
    closeLeaderboard: null
  },
  methods: {
    async fetchLeaderboard() {
      const url = this.baseUrl + "game/leaderboard"
      const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json'
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer'
      });
      if(response.ok && response.status === 200){
        this.leaderboard = await response.json()
      }
    },
    emitLeaderboardClose() {
      this.$emit("closeLeaderboard")
    }
  },
  data() {
    return {
      leaderboard: []
    }
  }
}
</script>

<style lang="sass">
@use "../assets/scss/_variables.sass"

.leaderboard-container
  position: relative
  display: flex
  flex-direction: column
  align-items: center
  width: 80%
  .petras-madness
    position: absolute
    top: -100px
    left: -120px
    height: 140%
    width: 250px
    background-color: variables.$secondary-font-color
    background-image: url("../assets/leaderboard_flowers.svg")
    background-size: auto 240px
    background-repeat: no-repeat
    background-position-y: bottom
    background-position-x: center
  .close-modal
    display: flex
    justify-content: flex-end
    width: 100%
    padding: 0 10% 2% 10%
    .close-modal-button
      text-align: start
      background: none !important
      padding: 0 !important
      border: none
      font-family: variables.$opensans-italic !important
      color: variables.$primary-orange
      cursor: pointer
      span
        content: url("../assets/+.svg")
        transform: rotate(45deg)
        width: 15px
        height: auto
  .grid-container
    z-index: 1
    background-color: white
    font-family: variables.$opensans-italic !important
    display: grid
    grid-template-columns: 0.2fr 1fr 0.5fr
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr
    gap: 0 0
    grid-template-areas: "Position Player Points" "pos pla poi" "pos pla poi" "pos pla poi" "pos pla poi" "pos pla poi" "pos pla poi" "pos pla poi" "pos pla poi" "pos pla poi" "pos pla poi"
    width: 100%
    .column
      border: variables.$primary-orange solid 2px
      .cell
        border-bottom: variables.$primary-orange solid 2px
        margin-bottom: -2px
        padding: 5px 10px 5px 10px
        color: variables.$primary-font-color
      .position-cell
        color: variables.$primary-font-color !important
      .name
        text-align: start
    .poi
      margin-left: -2px
    .pos
      margin-right: -2px
  .leaderboard-buttons
    display: flex
    justify-content: flex-start
    width: 100%
    padding-top: 5%
    z-index: 1
    button
      margin-left: 22%



.Position
  grid-area: Position
  background-color: variables.$primary-orange
  padding: 2% 5% 2% 5%
.Player
  grid-area: Player
  background-color: variables.$primary-orange
  display: flex
  justify-content: center
  align-items: center
  color: variables.$secondary-font-color
  padding: 2% 5% 2% 5%
  border-right: variables.$secondary-font-color solid 2px
  border-left: variables.$secondary-font-color solid 2px
.Points
  grid-area: Points
  background-color: variables.$primary-orange
  display: flex
  justify-content: center
  align-items: center
  color: variables.$secondary-font-color
  padding: 2% 5% 2% 5%
.pos
  grid-area: pos
.pla
  grid-area: pla
.poi
  grid-area: poi


@media screen and (max-width: 800px)
  .leaderboard-container
    .petras-madness
      left: -80px


@media screen and (max-width: 500px)
  .leaderboard-container
    .petras-madness
      left: -40px


@media screen and (max-width: 500px)
  .leaderboard-container
    .petras-madness
      left: -20px
      width: 200px
</style>