export default {
    menu: {
        participation: "PARTECIPAZIONE",
        gallery: "GALLERY",
        us: "NOI",
        location: "LOCATION",
        info: "INFO",
    },
    home: {
        participate: "PARTECIPO",
        gallery: "GALLERY",
        locationtitle: "Location",
        location: "Park Hill è un parco di nove ettari, immerso in un bosco situato a 5 km da Debrecen (HU). Abbiamo scelto questa location per il nostro matrimonio in quanto situato in un ambiente naturale lontano dai rumori della città ma facile da raggiungere. Nel sito sarà disponibile un'elegante tenda per eventi di 800 mq dove si terrà la cena e un ampio spazio verde nel quale è presente un padiglione esterno in ferro battuto, luogo perfetto per una cerimonia all'aperto.",
        locationdiscover: "Scopri di piu",
        programtitle: "Programma",
        //counter1: "Mancano solo",
        counter1: "Sono già passati",
        counter2: "GIORNI",
        //counter3: "per festeggiare insieme!"
        counter3: "dalla nostra festa insieme!"
    },
    participate: {
        title: "festeggia con noi",
        intro1: "Speriamo che ti unirai a noi per questo giorno speciale.",
        intro2: "Per favore compila questo form per confermare",
        intro3: "la tua partecipazione entro 1 Giugno 2021.",
        name: "Nome:",
        addguest: " Aggiungi un'altro ospite",
        travel: "Vorresti ricevere aiuto per il viaggio?",
        accommodation: "Vorresti ricevere aiuto per l'alloggio?",
        food: "Per favore, comunicaci eventuali intolleranze o diete particolari",
        other: "Altri commenti o domande?",
        send: "INVIO",
        successtext1: "Grazie per aver confermato la tua partecipazione! Non vediamo l'ora di festeggiare insieme!",
        successtext2: "Nell'attesa perchè non fai una patita con la ",
        successquestion: "?",
        successbutton: "VAI AL GIOCO"
    },
    us: {
        title: "I TOMATOES",
        intro: 'Abbiamo preparato per voi un piccolo gioco, aiutate Sodi a superare le aiuole di fiori per scoprire alcuni "segreti" su di noi. Le regole sono semplici, usa la barra spaziatrice per saltare e la freccia verso il basso per abbassarti. Evita le aiuole e gli uccelli che cerceranno di fermarti. Il gioco ha anche un concorso a premi, chi arriva più lontano nella #SODORun entro la data del matrimonio, riceverà un piccolo premio.',
        race: "Per partecipare alla gara",
        register: "Registrati",
        connector: "o",
        access: "Accedi",
        logout: "Log Out",
        welcome: "Ciao",
        points: "il tuo record è:",
        leaderboard: "GUARDA LA CLASSIFICA",
        fakesecret1: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean blandit malesuada libero, vehic",
        fakesecret2: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean blandit malesuada libero, vehicula p",
        fakesecret3: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean blandit malesuada libero, vehicula pretium massa fringilla sed. In aliquet, dolor vitae molestie volutpat, quam nibh sodales mauris, at",
        fakesecret4: "Lorem ipsum dolor sit amet, consectetur adipiscin",
        fakesecret5: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean blandit malesuada libero",
        fakesecret6: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean blandit malesuada libero, vehicula pretium massa fringilla sed. In",
        fakesecret7: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean blandit malesuada libero, vehicula pretium massa fringilla sed. In aliquet, dolor vitae",
        fakesecret8: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean blandit malesuada",
        secret1: "Prima di iniziare la nostra relazione eravamo veramente molto amici! (Andrea vs Friendzone 1-0)",
        secret2: "Poco fa abbiamo festeggiato il nostro ottavo anno insieme. Eh sì, tutto è iniziato nel lontano 2013!",
        secret3: "Le prime parole in Ungherese di Andrea sono state \"Benzineskanna\" (Tanica della benzina), \"Szempillaspirál\" (Mascara), \"Gémkapocs\" (Graffetta). Le ha imparate grazie a un gioco per tablet, utili... vero? :)",
        secret4: "Nel 2015 si è unito a noi il nostro piccolo Sodó!",
        secret5: "Stiamo cercando di finire un puzzle di 4000 pezzi da ben 6 anni! Credetemi è un'impresa!",
        secret6: "In questi anni abbiamo fatto tanti viaggi in macchina per poter portare con noi il nostro Sodó. Abbiamo percorso circa 135.253 km.",
        secret7: "Abbiamo una collezione veramente strana. In tutti gli appartamenti in affitto in cui abbiamo vissuto, ci siamo \"appropiati\" di un piccolo ricordo! ;)",
        secret8: "Nell'estate del 2016, IKEA presenta una pubblicità molto divertente. Dove un uomo rimane bloccato sul terrazzo e si trova costretto a \"sopravvivere\" con gli strumenti dell'IKEA. La canzone della pubblicità ci colpi molto e cantavamo tutto il giorno \"Don't touch me Tomato\". Da li a poco, iniziammo a chiamarci Tomato, Tomata e Tomatino (Sodó). Ecco la pubblicità: ",
        backbutton: "INDIETRO"
    },
    leaderboard: {
        leaderboard: "CLASSIFICA",
        name: "NOME",
        points: "PUNTI"
    },
    register:{
        title: "REGISTRATI",
        name: "Nome:",
        placeholdername: "Inserisci nome",
        phoneemail: "E-mail o numero di telefono:",
        placeholderphoneemail: "Inserisci e-mail o numero di telefono",
        pass: "Password:",
        placeholderpass: "Inserisci password",
        repeatpass: "Ripeti password:",
        placeholderrepeatpass: "Ripeti password",
        register: "REGISTRATI",
        success: "Registrazione avvenuta con successo!"
    },
    login:{
        title: "LOG IN",
        name: "Nome:",
        placeholdername: "Inserisci nome",
        pass: "Password:",
        placeholderpass: "Inserisci password",
        login: "LOG IN"
    },
    location: {
        intro: "Come anticipato, la location è immersa in un piccolo bosco. Non è raro, infatti, vedere scoiattoli e altri piccoli animaletti, tipici dei boschetti ungheresi, che si aggirano nei dintorni. Il parco presenta un ampio ingresso circondato dagli alberi, dove si svolgerà un piccolo aperitivo di benvenuto. Poco più avanti, è presente un ampio spiazzo dedicato alla cerimonia. Saranno disposte le sedie di fronte al padiglione in ferro battuto e chi vorrà potrà prendere posto per seguire la celebrazione. Oltre a ciò è presente un piccolo parco giochi per i bambini e potrete anche fare amicizia con alcuni cavalli stupendi. La cena e la festa si svolgeranno in una ampia tenda servita da aria condizionata. Se vuoi scoprire di più visita la galleria ufficiale al seguente indirizzo:",
        addresstop: "Kastély utca 2.",
        addressbottom: "Hajdúsámson - Martinka, Ungheria, 4251",
        howto: "COME ARRIVARE",
        cartitle: "MACCHINA",
        taxititle: "TAXI",
        bustitle: "BUS",
        car: "È molto semplice raggiungere la location in macchina. I luogo della cerimonia è molto vicino alla città ed è presente un parcheggio gratuito per gli ospiti. Se vuoi indicazioni inserisci la via di partenza qui sotto e cerca il percorso più veloce.",
        taxi: "La città di Debrecen è servita da varie compagnie di trasporto Taxi. I taxi sono prenotabili via telefono o tramite applicazione. Sono inoltre presenti molte stazioni taxi nella città.",
        bus: "In base alle richieste, organizzeremo un trasporto con bus privato per raggiungere la location del matrimonio e tornare in città una volta finita la festa. Facci sapere se sei interessato.",
        findpath: "CERCA PERCORSO"
    },
    info: {
        title: "INFORMAZIONI",
        intro: "Abbiamo raccolto in questa pagina alcune domande e risposte comuni riguardanti l'evento. Mentre le informazioni presenti alle pagine seguenti vi aiuteranno ad organizzare il vostro viaggio e soggiorno in Ungheria.",
    },
    questions: {
        question1: "La data potrebbe cambiare per via della pandemia causata dal coronavirus?",
        answer1: "La nostra intenzione è di celebrare il nostro matrimonio il 31 Luglio 2021, rispettando comunque le leggi in vigore in tale data. Vi terremo comunque informati riguardo eventuali aggiornamenti.",
        question2: "Esiste un dress code?",
        answer2: "Stiamo organizzando una festa estiva dove l'importante è divertirsi, quindi non ci sono regole speciali. Non dimenticare le tue scarpe da ballo a casa! ;)",
        question3: "Ci sarà un menu separato per chi segue una dieta speciale?",
        answer3: "Sì, se hai esigenze particolari, faccelo sapere quando confermi la tua partecipazione.",
        question4: "Posso portare ospiti aggiuntivi?",
        answer4: "L'idea di celebrare il nostro matrimonio in Ungheria è nata per poter dare l'opportunità a chi viene dall'estero di poter fare anche un vacanza in questo paese e scoprire le sue meraviglie. Perciò se vorrete organizzare un viaggio con i vostri amici o parenti sarete tutti benvenuti. Cogliete l'occasione per una vacanza indimenticabile!",
        question5: "Esiste una lista nozze?",
        answer5: "No, per noi la tua presenza è il regalo più grande. Tuttavia, se vuoi sorprenderci, puoi contribuire al nostro viaggio di nozze.",
        question6: "La location del matrimonio è provvista di alloggi?",
        answer6: "No, l'alloggio dovrà essere prenotato separatamente. La location comunque è veramente vicina al centro città e quindi facilmente raggiungibile."
},
    game: {
      init: "Premi SPAZIO o fai TAP per iniziare il gioco"
    },
    errors: {
        name: "Nome troppo corto, lunghezza minima 2 caratteri.",
        username: "Username troppo corto, lunghezza minima 2 caratteri.",
        usernamenotavailable: "Username è gia presente. Scegline un altro",
        email: "E-mail troppo corta, lunghezza minima 4 caratteri.",
        emailnotvalid: "E-mail o numero di telefono non validi.",
        emailnotavailable: "E-mail o numero di telefono già presente. Scegline un'altra/o.",
        password: "Password troppo corta, lunghezza minima 4 caratteri.",
        passwordnotvalid: "Le password non combaciano."
    },
    gallery: {
        video: "VIDEO",
        videoc: "Video Completo",
        foto: "FOTO"
    }
}