import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
//import InvitedForm from '../views/InvitedForm.vue'
import GallerySecured from '../views/GallerySecured.vue'
import Album from '../views/Album.vue'
import Us from '../views/Us.vue'
import Location from '../views/Location.vue'
import Info from '../views/Info'
import Accommodation from "../views/Accommodation"
import Travel from "../views/Travel"
import Vacation from "../views/Vacation"

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/:locale',
    redirect: '/'
  },
  // Removed because is not needed anymore.
  //{
  //  path: '/:locale/take-part',
  //  name: 'InvitedForm',
  //  component: InvitedForm
  //},
  {
    path: '/:locale/gallery',
    name: 'GallerySecured',
    component: GallerySecured
  },
  {
    path: '/:locale/gallery/:name',
    name: 'Album',
    component: Album
  },
  {
    path: '/:locale/about-us',
    name: 'Us',
    component: Us
  },
  {
    path: '/:locale/location',
    name: 'Location',
    component: Location
  },
  {
    path: '/:locale/info',
    name: 'Info',
    component: Info
  },
  {
    path: '/it/info/accommodation',
    name: 'Accommodation',
    component: Accommodation
  },
  {
    path: '/it/info/travel',
    name: 'Travel',
    component: Travel
  },
  {
    path: '/it/info/vacation',
    name: 'Vacation',
    component: Vacation
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
