<template>
  <div class="accommodation-container">
    <div class="accommodation-intro-container">
      <div class="accommodation">
        <h1>ALLOGGIO</h1>
      </div>
      <div class="accommodation-intro">
        <p class="accommodation-desc">In questa pagina abbiamo raccolto per voi alcuni portali dove poter cercare alberghi e appartamenti per la durata del vostro soggiorno. Inoltre abbiamo raccolto qui gli alberghi più popolari di Debrecen! Potete organizzare il vostro soggiorno in maniera indipendente oppure, in base ai vostri piani: durata e date di arrivo, possiamo aiutarvi a prenotare nella struttura scelta. In tal caso, per favore, durante la registrazione nella pagina "PARTECIPAZIONE" segnalate la volontà di essere aiutati.</p>
        <div class="accommodation-link">
          <a class="booking" href="https://www.booking.com/city/hu/debrecen.it.html">BOOKING</a>
          <a class="expedia" href="https://www.expedia.it/Debrecen-Hotel.d6837.Guida-Viaggi-Hotel">EXPEDIA</a>
          <a class="airbnb" href="https://www.airbnb.it/debrecen-hungary/stays">AIRBNB</a>
          <a class="trivago" href="https://www.trivago.it/debrecen-81132/hotel">TRIVAGO</a>
        </div>
      </div>
    </div>
    <div class="hotel-container">
      <div class="h1">
        <a href="http://hotel.malomhoteldebrecen.hu/?lang=it" target="_blank">
          <img src="../assets/accommodation/malom.jpg">
          <p>Malom Hotel</p>
        </a>
      </div>
      <div class="h2">
        <a href="https://www.hoteldivinus.hu/en/" target="_blank">
          <img src="../assets/accommodation/divinus.jpg">
          <p>Hotel Divinus</p>
        </a>
      </div>
      <div class="h3">
        <a href="https://www.hotelobester.hu/en" target="_blank">
          <img src="../assets/accommodation/obester.jpg">
          <p>Hotel Óbester</p>
        </a>
      </div>
      <div class="h4">
        <a href="https://hotel.aquaticum.hu/en" target="_blank">
          <img src="../assets/accommodation/aquaticum.jpg">
          <p>Aquaticum Termal & Wellness Hotel</p>
        </a>
      </div>
      <div class="h5">
        <a href="http://www.centrumhotel.hu/?lang_id=eng" target="_blank">
          <img src="../assets/accommodation/centrum.jpg">
          <p>Centrum Hotel</p>
        </a>
      </div>
      <div class="h6">
        <a href="https://www.hotellycium.hu/en" target="_blank">
          <img src="../assets/accommodation/lycium.jpg">
          <p>Hotel Lycium</p>
        </a>
      </div>
    </div>
    <div class="accommodation-note">
      <p>Questi sono solo alcuni degli alberghi presenti a Debrecen. Ci sono inoltre moltissime strutture più piccole come Bed and Breakfast e appartamenti. Per qualsiasi dubbio o domanda riguardante l'alloggio scelto, non esitate a contattarci.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Accommodation',
  mounted() {
    this.scrollToTop()
  }
}
</script>

<style lang="sass">
@use "../assets/scss/_variables.sass"

.accommodation-container
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  .accommodation-intro-container
    display: flex
    width: 60%
    margin: 4% 0 4% 0
    .accommodation
      border-right: variables.$primary-orange solid 2px
      display: flex
      align-items: center
      padding-right: 4%
    .accommodation-intro
      display: flex
      flex-direction: column
      padding-left: 4%
      .accommodation-desc
        margin-top: 0
      .accommodation-link
        display: flex
        justify-content: space-around
        a
          font-family: variables.$opensans-italic
          color: variables.$primary-orange
          text-decoration: none
          border-bottom: variables.$secondary-font-color solid 12px
          height: 14px
        .booking
          width: 78px
        .expedia
          width: 70px
        .airbnb
          width: 64px
        .trivago
          width: 70px
  .hotel-container
    display: grid
    grid-template-columns: 1fr 1fr 1fr
    grid-template-rows: 1fr 1fr
    gap: 5% 5%
    grid-template-areas: "h1 h2 h3" "h4 h5 h6"
    width: 60%
    div
      display: flex
      flex-direction: column
      a
        text-decoration: none
        width: 70%
        img
          width: 100%
          height: auto
        p
          color: variables.$primary-orange
          font-family: variables.$opensans-italic
    .h1
      grid-area: h1
      align-items: flex-start
    .h2
      grid-area: h2
      align-items: center
    .h3
      grid-area: h3
      align-items: flex-end
    .h4
      grid-area: h4
      align-items: flex-start
    .h5
      grid-area: h5
      align-items: center
    .h6
      grid-area: h6
      align-items: flex-end
  .accommodation-note
    margin: 0
    background-image: url("../assets/sodo_footer_flowers.jpg")
    background-size: auto 260px
    background-repeat: no-repeat
    background-position-y: bottom
    background-position-x: center
    display: flex
    justify-content: center
    p
      width: 80%
      margin: 4% 0 8% 0

@media screen and (max-width: 1000px)
  .accommodation-container
    .accommodation-intro-container
      width: 80%
    .accommodation-note
      background-size: auto 200px
    .hotel-container
      grid-template-columns: 1fr 1fr
      grid-template-rows: 1fr 1fr 1fr
      grid-template-areas: "h1 h2" "h3 h4" "h5 h6"
      margin: 4% 0 2% 0
      padding-bottom: 6%
      .h1
        align-items: center
      .h3
        align-items: center
      .h4
        align-items: center
      .h6
        align-items: center

@media screen and (max-width: 700px)
  .accommodation-container
    .accommodation-intro-container
      flex-direction: column
      align-items: center
      .accommodation
        border-right: none
        border-bottom: variables.$primary-orange solid 2px
        padding: 0
      .accommodation-intro
        margin-top: 10%

@media screen and (max-width: 600px)
  .accommodation-container
    .accommodation-note
      background-size: auto 160px
    .hotel-container
      display: flex
      flex-direction: column
      align-items: center
      justify-content: center
      width: 80%
      gap: normal
      padding-bottom: 0
      div
        a
          p
            margin: 0.5% 0 14% 0

@media screen and (max-width: 500px)
  .accommodation-container
    .accommodation-intro-container
      .accommodation-intro
        align-items: center
        .accommodation-link
          display: grid
          grid-template-columns: 1fr 1fr
          grid-template-rows: 1fr 1fr
          gap: 35% 5%
          grid-template-areas: "booking expedia" "airbnb trivago"
          width: 60%
          justify-items: center
          padding-bottom: 10%

@media screen and (max-width: 350px)
  .accommodation-container
    .accommodation-intro-container
      .accommodation-intro
        .accommodation-link
          gap: 35% 25%
</style>