<template>
  <div class="home">
    <img ref="artboard" alt="main artboard">
    <router-link :to="{path: `/${this.$store.state.locale}/gallery`}"><div>{{ this.$t("home.gallery", this.$store.state.locale) }}</div></router-link>
  </div>
  <div class="location">
    <div class="location-header-mobile">
      <h1>{{ this.$t("home.locationtitle", this.$store.state.locale) }}</h1>
    </div>
    <div class="location-body">
      <div class="location-text">
        <div class="location-header">
          <h1>{{ this.$t("home.locationtitle", this.$store.state.locale) }}</h1>
        </div>
        <p :class="localeHu">{{ this.$t("home.location", this.$store.state.locale) }}</p>
        <div class="location-button">
          <button>{{ this.$t("home.locationdiscover", this.$store.state.locale) }}</button>
        </div>
      </div>
      <div class="location-img">
        <img alt="location-img" src="../assets/sodo_parkhill.svg">
      </div>
    </div>
  </div>
  <div class="prog">
    <h1>{{ this.$t("home.programtitle", this.$store.state.locale) }}</h1>
    <img ref="progimg" class="prog-img" alt="prog-img">
  </div>
  <div class="info" v-if="this.$store.state.locale === 'it'">
    <h1>Informazioni</h1>
    <div class="info-container">
      <router-link class="travel" :to="{path: `/it/info/travel`}">
        <img src="../assets/sodo_travel.svg">
      </router-link>
      <router-link class="accommodation" :to="{path: `/it/info/accommodation`}">
        <img src="../assets/sodo_accomodation.svg">
      </router-link>
      <router-link class="what-to-do" :to="{path: `/it/info/vacation`}">
        <img src="../assets/sodo_what_to_do.svg">
      </router-link>
    </div>
  </div>
  <hr v-if="this.$store.state.locale === 'it'" />
  <div class="counter">
    <p>{{ this.$t("home.counter1", this.$store.state.locale) }}</p>
    <p class="days">{{dayToTheDay()}} {{ this.$t("home.counter2", this.$store.state.locale) }}</p>
    <p>{{ this.$t("home.counter3", this.$store.state.locale) }}</p>
  </div>
</template>

<script>
import deskartboardHu from '../assets/sodo_artboard_hu.jpg'
import mobileartboardHu from '../assets/sodo_artboard_mobile_hu.jpg'
import deskartboardIt from '../assets/sodo_artboard_it.jpg'
import mobileartboardIt from '../assets/sodo_artboard_mobile_it.jpg'

import deskprogramHu from "../assets/sodo_icons_hu.svg"
import mobileprogramHu from "../assets/sodo_icons_mobile_hu.svg"
import deskprogramIt from "../assets/sodo_icons_it.svg"
import mobileprogramIt from "../assets/sodo_icons_mobile_it.svg"

export default {
  name: 'Home',
  mounted() {
    this.scrollToTop()
    this.mountCorrectArtboard()
    this.mountCorrectProgram()
    window.addEventListener('resize', () => {
      this.mountCorrectArtboard()
      this.mountCorrectProgram()
    });
  },
  methods: {
    dayToTheDay() {
      const today = new Date();
      const theDay = new Date("07/31/2021");
      const time = theDay.getTime() - today.getTime();
      return Math.abs(Math.floor(time / (1000 * 3600 * 24)));
    },
    mountCorrectArtboard() {
      if(window.innerWidth < 900) {
        this.mobileArtboardLocale()
      } else {
        this.deskArtboardLocale()
      }
    },
    deskArtboardLocale() {
      if(this.$store.state.locale === 'hu') {
        this.$refs.artboard.src = deskartboardHu
      } else {
        this.$refs.artboard.src = deskartboardIt
      }
    },
    mobileArtboardLocale() {
      if(this.$store.state.locale === 'hu') {
        this.$refs.artboard.src = mobileartboardHu
      } else {
        this.$refs.artboard.src = mobileartboardIt
      }
    },
    mountCorrectProgram() {
      if(window.innerWidth < 920) {
        this.mobileProgramLocale()
      } else {
        this.deskProgramLocale()
      }
    },
    deskProgramLocale() {
      if(this.$store.state.locale === 'hu') {
        this.$refs.progimg.src = deskprogramHu
      } else {
        this.$refs.progimg.src = deskprogramIt
      }
    },
    mobileProgramLocale() {
      if(this.$store.state.locale === 'hu') {
        this.$refs.progimg.src = mobileprogramHu
      } else {
        this.$refs.progimg.src = mobileprogramIt
      }
    }
  },
  computed: {
    localeHu: function () {
      return {
        spacing: this.$store.state.locale === 'hu'
      }
    }
  }
}
</script>

<style lang="sass">
@use "../assets/scss/_variables.sass"
.home
  height: 450px
  overflow: hidden
  display: flex
  justify-content: center
  position: relative
  img
    height: 450px
  a
    position: absolute
    width: 226px
    height: 43px
    top: 80%
    left: 50%
    margin-left: -113px
    background-color: variables.$primary-orange
    border: none
    color: variables.$secondary-font-color
    text-decoration: none
    display: flex
    justify-content: center
    align-items: center

.location
  display: flex
  flex-direction: column
  align-items: center
  margin: 2% 0 2% 0
  .location-header-mobile
    display: none
  .location-body
    display: flex
    width: 80%
    .location-text
      .location-header
        display: flex
        justify-content: flex-start
        width: 80%
      p
        text-align: justify
        margin: 1% 10% 0 5%
      .location-button
        display: flex
        justify-content: flex-start
        margin-top: 3%
        button
          margin-left: 5%
          background-color: white
          border-left: 2px solid variables.$primary-orange
          border-top: none
          border-right: none
          border-bottom: none
          font-size: variables.$font-18
          font-family: variables.$opensans-italic !important
          color: variables.$primary-orange
          text-align: center
          text-decoration: none
          cursor: pointer


  .location-img
    flex: 1
    display: flex
    justify-content: center
    align-items: center
    img
      position: relative
      height: 238px
      width: auto
      z-index: 1


.prog
  background-color: variables.$primary-light-orange
  padding: 3% 0 3% 0
  h1
    margin-top: 0
  img
    margin: 0 11% 0 11%

.info
  h1
    margin: 3% 0 2% 0
  .info-container
    display: flex
    justify-content: space-evenly

hr
  margin: 5% 10% 3% 10%
  border: 1px solid variables.$primary-orange

.counter
  margin-bottom: 0
  background-image: url("../assets/sodo_footer_flowers.jpg")
  background-size: auto 260px
  background-repeat: no-repeat
  background-position-y: bottom
  background-position-x: center
  .days
    font-family: variables.$agatho-light-caps
    color: variables.$primary-orange
    font-size: variables.$font-100
    margin: 0
  p
    font-size: 28px
  p:last-child
    margin-bottom: 0
    padding-bottom: 5%

@media screen and (max-width: 1200px)
  .location
    .location-img
      img
        position: relative
        height: 200px
        z-index: 1


@media screen and (max-width: 1000px)
  .location
    .location-img
      img
        position: relative
        height: 160px
        z-index: 1

  .counter
    background-size: auto 200px
    p:last-child
      margin-bottom: 0
      padding-bottom: 10%

@media screen and (max-width: 920px)
  .location
    .location-img
      img
        margin: 10% 0 12% 0
    .location-header-mobile
      display: flex
      justify-content: flex-start
      width: 80%
      margin-bottom: -10%
    .location-body
      display: flex
      flex-direction: column-reverse
      .location-text
        margin: -10% 0 4% 0
        .location-header
          display: none
  .prog
    display: flex
    flex-direction: column
    align-items: center
    h1
      margin: 0
    p
      width: 80%
    .prog-img
      width: 32%
  .info
    .info-container
      display: flex
      flex-direction: column
      align-items: center
      a
        margin-bottom: 2%

@media screen and (max-width: 800px)
  .home
    height: 300px
    img
      height: 300px

  .info
    h1
      margin: 6% 0 4% 0
    .info-container
      a
        margin-bottom: 4%

  hr
    margin: 5% 10% 5% 10%

  .counter
    .days
      font-size: 80px
    p
      font-size: 22px

@media screen and (max-width: 600px)
  .counter
    background-size: auto 160px
    p:last-child
      margin-bottom: 0
      padding-bottom: 15%

@media screen and (max-width: 500px)
  .home
    height: 225px
    a
      width: 108px
      height: 28px
      margin-left: -54px
      font-size: 0.9rem

    img
      height: 225px

  .info
    h1
      margin: 8% 0 6% 0
    .info-container
      a
        margin-bottom: 8%

  .prog
    .prog-img
      width: 50%

  .counter
    .days
      font-size: 60px
    p
      font-size: 20px
    p:first-child
      margin: 10% 0 0 0
    p:last-child
      margin-top: 0
      padding-bottom: 10%

  .location
    .location-body
      .location-text
        .spacing
          word-spacing: -2px

@media screen and (max-width: 380px)
  .location
    .location-body
      .location-text
        .spacing
          word-spacing: -3px
</style>