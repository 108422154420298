<template>
  <div class="game">
    <div ref="messageBox" id="messageBox" v-bind:class="{ sendmessage: sendMessageActive, 'sendmessage-hidden': !sendMessageActive }">
      <h1 style="text-align: center;font-family: 'Open Sans', sans-serif;">{{ this.$t("game.init", this.$store.state.locale) }}</h1>
      <div class="niokbutton" onclick="okbuttonsend()"></div>
    </div>
    <Runner :points="this.points"/>
  </div>
</template>

<script>
import Runner from "@/components/Runner";

export default {
  name: 'Game',
  components: {Runner},
  props: ['points'],
  mounted: function () {
    let self = this
    document.addEventListener( 'keydown', function(e) {
      e = e || window.event;
      if (e.keyCode === 32) {
        self.sendMessageActive = false
      }
    })
  },
  data: function () {
    return {
      sendMessageActive: true
    }
  }
}
</script>

<style  lang="sass">
@use "../assets/scss/_variables.sass"

.sendmessage-hidden
  visibility: hidden
.game
  margin-bottom: 5%
  #messageBox
    h1
      font-family: variables.$opensans-italic !important
      font-size: 18px !important
      margin-top: 2%

@media screen and (max-width: 500px)
  .game
    #messageBox
      h1
        margin-top: 5%

</style>
