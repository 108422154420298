<template>
  <div class="lb-grid">
    <template v-for="(src, i) in items" v-bind:key="src">
      <a class="lb-item" :href="items[i]" role="link" @click.prevent="show(i)" :style="bg(src)">
      </a>
    </template>
  </div>

  <transition name="fade">
    <div class="modal" v-if="index>=0">
      <div class="modal-header">
        <button class="button-download" @click="downloadPic">
          <i class="fas fa-download"></i>
        </button>
        <button class="button-close" @click="close">
          <i class="fas far fa-times"></i>
        </button>
      </div>
      <div class="modal-body" v-touch:swipe.left="prev" v-touch:swipe.right="next">
        <button class="button-left" @click="prev">
          <i class="fas far fa-angle-left fa-2x"></i>
        </button>
        <img :src="src">
        <button class="button-right" @click="next">
          <i class="fas far fa-angle-right fa-2x"></i>
        </button>
      </div>
    </div>
  </transition>
  <transition name="fade">
    <div class="spinner" v-if="loading">
      <fade-loader :loading="loading" :color="color"></fade-loader>
    </div>
  </transition>
</template>

<script>
import FadeLoader from 'vue-spinner/src/FadeLoader.vue'
export default {
  name: "SlideShow",
  components: {
    FadeLoader
  },
  props: {
    items: {
      type: Array,
      default: () => { return [] }
    },
  },
  mounted() {
    setTimeout(
        () => {
          this.loading = false
        }, 5000);
  },
  data: function () {
    return {
      src: '',
      index: -1,
      events: [],
      loading:true,
      color: "#e9bf59"
    }
  },

  methods: {
    bind() {

      if (this.events.length > 0) return;

      this.events.push(['keydown', e => {
        if (this.index < 0) return;
        if (e.keyCode === 37) {
          this.prev();
        } else if (e.keyCode === 39) {
          this.next();
        } else if (e.keyCode === 27) {
          this.close();
        }
      }]);
      this.events.forEach(e => { window.addEventListener(e[0], e[1]); });
    },
    async downloadPic() {
      this.loading = true;
      const fotoNameArray = this.items[this.index].split("/")
      const fotoName = fotoNameArray[fotoNameArray.length-1]
      const url = this.baseUrl + "foto/download?folder=" + this.$route.params.name + "&name=" + encodeURIComponent(fotoName);
      const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.$store.state.fotoToken
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer'
      });
      if(response.ok && response.status === 200){
        const blob = await response.blob();
        const newBlob = new Blob([blob]);

        const blobUrl = window.URL.createObjectURL(newBlob);

        const link = document.createElement('a');
        link.href = blobUrl;
        link.setAttribute('download', `${fotoName}`);
        document.body.appendChild(link);
        var click_ev = document.createEvent("MouseEvent");
        click_ev.initEvent("click", false /* bubble */, true /* cancelable */);
        link.dispatchEvent(click_ev);
        link.parentNode.removeChild(link);

        window.URL.revokeObjectURL(blob);
        this.loading = false;
      } else {
        this.loading = false;
      }
    },
    show(i) {
      if (i >= this.items.length) {
        i = 0;
      }
      if (i < 0) {
        i = this.items.length - 1;
      }
      this.loading = true;
      this.bind();
      this.index = i;
      var src = this.items[i];
      var img = new Image();
      img.src = src;
      img.onload = () => {
        this.loading = false;
        this.src = src;
      }
    },
    next() {
      this.show(this.index - 1);
    },

    prev() {
      this.show(this.index + 1);
    },
    close() {
      this.index = -1;
      this.events.forEach(e => { window.removeEventListener(e[0], e[1]); });
      this.events = [];
    },
    bg(i) {
      return i && i.length > 0 ? `background-image: url('${i}')` : '';
    }

  }
}
</script>

<style  lang="sass">
@use "../assets/scss/_variables.sass"
.button-close, .button-download
  background: none
  color: inherit
  border: none
  padding: 0
  font: inherit
  cursor: pointer
  outline: inherit

.arrow
  margin: 100%
  padding: 100%

.lb-grid
  height: 100%
  position: relative
  display: flex
  justify-content: center
  flex-wrap: wrap
  margin-bottom: 5%

.lb-item
  background-position: center center
  background-repeat: no-repeat
  background-size: cover
  border-top: solid 2px #fff
  border-right: solid 2px #fff
  height: 200px
  width: 300px


.lb-item:hover, .lb-item:focus
  opacity: 0.6

.spinner
  position: fixed
  z-index: 1
  left: 0
  top: 0
  width: 100%
  height: 100%
  overflow: hidden
  background-color: rgb(255, 255, 255, 0.8)
  justify-content: center
  align-items: center
  display: flex

.modal
  position: fixed
  z-index: 1
  left: 0
  top: 0
  width: 100%
  height: 100%
  overflow: hidden
  background-color: rgb(255, 255, 255, 0.8)
  justify-content: center
  align-items: center
  display: flex
  flex-direction: column
  .modal-header
    position: absolute
    top: 0
    left: 0
    display: flex
    justify-content: flex-end
    height: 60px
    width: 100%
    .button-close, .button-download
      width: 10%
      height: 100%
      svg
        font-size: 2em !important
        color: variables.$primary-orange
  .modal-body
    padding: 100px
    box-sizing: border-box
    max-height: 100%
    width: 100%
    flex: 1
    display: flex
    justify-content: space-between
    .button-left, .button-right
      display: flex
      align-items: center
      width: 20%
      background: none
      color: inherit
      border: none
      padding: 0
      font: inherit
      cursor: pointer
      outline: inherit
      svg
        color: variables.$primary-orange
    .button-right
      justify-content: flex-end
    .button-left
      justify-content: flex-start
    img
      object-fit: contain
      width: 100%
      height: auto

@media screen and (max-width: 1000px)
  .button-left, .button-right
    display: none !important
@media screen and (max-width: 800px)
  .modal-header
    justify-content: space-between !important
@media screen and (max-width: 700px)
  .modal-body
    padding: 0px !important
</style>