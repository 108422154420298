<template>
  <teleport to="nav"><Menu /></teleport>
  <router-view/>
  <Footer />
</template>

<script>
import Menu from '@/components/Menu.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'SODOApp',
  components: {
    Menu,
    Footer
  }
}
</script>

<style lang="sass">
@use "./assets/scss/_variables.sass"
h1, h2, h3, h4, h5, h6
  font-family: variables.$agatho-light-caps
  color: variables.$primary-orange
h1
  font-size: 40px
p
  color: variables.$primary-font-color
  font-size: 15px
label
  color: variables.$primary-font-color
  font-size: 15px
button
  font: 300 15px variables.$opensans-light !important
  font-size: 15px
body
  font-family: variables.$opensans-light !important
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  text-align: center
  margin: 0 auto
@media screen and (max-width: 1000px)
  h1
    font-size: 30px
  p
    font-size: 13px
  label
    font-size: 13px
  button
    font-size: 13px
</style>
