import {Runner} from "@/game/Runner";
import {Util} from "@/game/Util";

/**
 * Nightmode shows a moon and stars on the horizon.
 */
export function NightMode(canvas, spritePos, containerWidth) {
    this.spritePos = spritePos;
    this.canvas = canvas;
    this.canvasCtx = canvas.getContext('2d');
    this.xPos = containerWidth - 50;
    this.yPos = 30;
    this.currentPhase = 0;
    this.opacity = 0;
    this.containerWidth = containerWidth;
    this.stars = [];
    this.drawStars = false;
    this.placeStars();
}

/**
 * @enum {number}
 */
NightMode.config = {
    FADE_SPEED: 0.035,
    HEIGHT: 80,
    MOON_SPEED: 0.25,
    NUM_STARS: 2,
    STAR_SIZE: 18,
    STAR_SPEED: 0.3,
    STAR_MAX_Y: 70,
    WIDTH: 40
}

NightMode.phases = [280, 240, 200, 120, 80, 40, 0];

NightMode.prototype = {
    /**
     * Update moving moon, changing phases.
     * @param {boolean} activated Whether night mode is activated.
     * @param {number} delta
     */
    update: function (activated) {
        // Moon phase.
        if (activated && this.opacity === 0) {
            this.currentPhase++;

            if (this.currentPhase >= NightMode.phases.length) {
                this.currentPhase = 0;
            }
        }

        // Fade in / out.
        if (activated && (this.opacity < 1 || this.opacity === 0)) {
            this.opacity += NightMode.config.FADE_SPEED;
        } else if (this.opacity > 0) {
            this.opacity -= NightMode.config.FADE_SPEED;
        }

        // Set moon positioning.
        if (this.opacity > 0) {
            this.xPos = this.updateXPos(this.xPos, NightMode.config.MOON_SPEED);

            // Update stars.
            if (this.drawStars) {
                for (var i = 0; i < NightMode.config.NUM_STARS; i++) {
                    this.stars[i].x = this.updateXPos(this.stars[i].x,
                        NightMode.config.STAR_SPEED);
                }
            }
            this.draw();
        } else {
            this.opacity = 0;
            this.placeStars();
        }
        this.drawStars = true;
    },

    updateXPos: function (currentPos, speed) {
        if (currentPos < -NightMode.config.WIDTH) {
            currentPos = this.containerWidth;
        } else {
            currentPos -= speed;
        }
        return currentPos;
    },

    draw: function () {
        const moonSourceWidth = this.currentPhase === 3 ? NightMode.config.WIDTH * 2 :
            NightMode.config.WIDTH;
        const moonSourceHeight = NightMode.config.HEIGHT;
        const moonSourceX = this.spritePos.x + NightMode.phases[this.currentPhase];
        const moonOutputWidth = moonSourceWidth/2;
        const moonOutputHeight = moonSourceHeight/2;
        const outputStarSize = NightMode.config.STAR_SIZE/2;
        const starSourceX = Runner.spriteDefinition.HDPI.STAR.x;

        this.canvasCtx.save();
        this.canvasCtx.globalAlpha = this.opacity;

        // Stars.
        if (this.drawStars) {
            for (let i = 0; i < NightMode.config.NUM_STARS; i++) {
                this.canvasCtx.drawImage(Runner.imageSprite,
                    starSourceX, this.stars[i].sourceY,
                    NightMode.config.STAR_SIZE, NightMode.config.STAR_SIZE,
                    Math.round(this.stars[i].x), this.stars[i].y,
                    outputStarSize, outputStarSize);
            }
        }

        // Moon.
        this.canvasCtx.drawImage(Runner.imageSprite, moonSourceX,
            this.spritePos.y, moonSourceWidth, moonSourceHeight,
            Math.round(this.xPos), this.yPos,
            moonOutputWidth, moonOutputHeight);

        this.canvasCtx.globalAlpha = 1;
        this.canvasCtx.restore();
    },

    // Do star placement.
    placeStars: function () {
        const segmentSize = Math.round(this.containerWidth /
            NightMode.config.NUM_STARS);

        for (let i = 0; i < NightMode.config.NUM_STARS; i++) {
            this.stars[i] = {}
            this.stars[i].x = Util.getRandomNum(segmentSize * i, segmentSize * (i + 1));
            this.stars[i].y = Util.getRandomNum(0, NightMode.config.STAR_MAX_Y);
            this.stars[i].sourceY = Runner.spriteDefinition.HDPI.STAR.y +
                NightMode.config.STAR_SIZE * i;
        }
    },

    reset: function () {
        this.currentPhase = 0;
        this.opacity = 0;
        this.update(false);
    }

}